import { Box, CssBaseline, Toolbar } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Component/layout/Nav";
import SideNav from "../Component/layout/SideNav";
import { drawer } from "../Redux/data";
import { Navigate } from "react-router-dom";

const ProtectedRoute = (props) => {
  const drawerWidth = 200;
  const [open, setOpen] = useState(true);
  const drawerOpen = useSelector((state) => state.data.drawerOpen);
  const login = useSelector((state) => state.auth.loginInfo.jwtToken);

  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    if (drawerOpen.bol) {
      dispatch(drawer({ bol: drawerOpen.bol, open: !drawerOpen.open }));
    } else {
      setOpen(!open);
    }
  };
  return (
    <>
    {login !== undefined && login !== null && login !== ""?
      <Box sx={{ background: 'linear-gradient(to right, #00807F, #FD0000)', minHeight: "100vh" }}>
        <CssBaseline />
        <Nav drawerOpen={handleDrawerOpen} />
        <SideNav open={open} drawerWidth={drawerWidth} res={"main"} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            background:'transparent',
            // p: 2,
            minHeight:"100vh",
            p: { xs: "1rem 0.5rem", md: "1.5rem 1rem" },
            ml: {
              xs: 0,
              md: (drawerOpen.bol ? drawerOpen.open : open)
                ? `${drawerWidth}px`
                : 0,
            },
            transition: (theme) =>
              theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }}
        >
          <Toolbar />

          {props.children}
        </Box>
      </Box>
      :<Navigate to="/" replace />
        }
    </>
  );
};

export default ProtectedRoute;
