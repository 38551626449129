export const commonCss={
    green:"#00807F",
    red:"#FD0000",
    shadeGreen:"#DAF2F2",
    shadeRed:"#FEDADA",
    shadeLinearGradient:"linear-gradient(to right, #DAF2F2, #FEDADA)",
    normalLinearGradient:"linear-gradient(to right, #00807F, #FD0000)",
    inputBorderRadius:"13px",
    inputBorderColor:"#747373",
    labelPadding:0.5,
    labelFontWeight:"500",
    labelInputColor:"black",
    inputBackgroundColor:"white",
    buttonBorderRadius:"13px",
    buttonTextColor:"white",
    mainLabelHeading:"bold",
    normalSentenceWeight:"bold",
    normalSenternceColor:"black",
    outSideSentenceColor:"white",
    paperBorderRadius:"10px",
    titleFontSize:"1.5rem",
    titleFontWeight:600,
    titleColor:"#FD0000"
}