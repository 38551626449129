import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";

import TerminalModal from "./TerminalModal";
import { api } from "../../API/config";
import { commonCss } from "../../../StyleConfig/CommonCss";
import TableContainerComp from "../../../Component/TableContainer";
import { axiosPrivate } from "../../../axios/axios";

const Terminal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [filterValue, setFilterValue] = useState("enableData");
  const [backUpData, setBackUpData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const getLink=api.terminal.getAll
   await axiosPrivate.get(`${getLink}`).then(res=>{
    console.log(res.data.data)
    setData(res.data.data)
    setIsLoading(false)
   }).catch(e=>{
    console.log(e)
    setIsLoading(false)
   })
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  // const handleDelete = (id) => {
  //   const deleteLink = masterAPI["assetStatus"].delete;
  //   axiosPrivate
  //     .delete(`${deleteLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "deleteassetStatus");
  //     });
  // };

  const handleDelete = async (e, id) => {};

  const columns = [
    // {
    //   accessor: "id",
    //   disableFilters: true,
    //   Cell: () => {
    //     return (
    //       <Box sx={{width:color.checkBoxWidth }}>
    //       <Checkbox
    //         // icon={<RadioButtonUncheckedOutlined />}
    //         // checkedIcon={<CheckCircle />}
    //       />
    //       </Box>
    //     );
    //   },
    // },
    {
      Header: "Name",
      accessor: "name",
      // disableFilters: true,
    },
    {
      Header: "Description",
      accessor: "description",
      // disableFilters: true,
    },

    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton>
              <EditOutlined
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
                sx={{
                  // fontSize: color.masterEditDelButton,
                  color: commonCss.red,
                }}
              />
            </IconButton>
            {/* <IconButton disabled={!actionsAllow.delete}>
              <DeleteOutline
                onClick={() => {
                  handleDelete(cell.row.original.id);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:actionsAllow.delete? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton> */}
            {/* <Switch
            disabled={!actionsAllow.delete}
            checked={cell.row.original.isActive}
            onChange={(e)=>{handleDelete(e,cell.row.original.id)}}
           
             /> */}
          </Box>
        );
      },
    },
  ];

  const handleChangeFilter = (e) => {
    if (e === "showAll") {
      setData(backUpData);
    } else {
      const filterDataResult = backUpData.filter((fil) => fil.isActive);

      setData(filterDataResult);
    }
    setFilterValue(e);
  };

  return (
    <>
      <Box>
        {/* <Typography
          sx={{
            color: commonCss.titleColor,
             fontSize: commonCss.titleFontSize,
             fontWeight: commonCss.titleFontWeight,
             mb:1
          }}
        >
          {api.terminal.title}
        </Typography> */}
      </Box>

      <TableContainerComp
        columns={columns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        title={api.terminal.title}
        // buttonDisable={!actionsAllow.create}
        // filterValue={filterValue}
        // handleChange={handleChangeFilter}
        // filterShow={true}
      />

      <Dialog
        // maxWidth={"lg"}
        open={create}
        onClose={handleCreateClose}
        PaperProps={{
          sx: {
            maxWidth: { xs: "100vw", md: "50vw" },
            width: { xs: "100vw", md: "50vw" },
            background: commonCss.shadeLinearGradient,
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent>
          <TerminalModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        </DialogContent>
      </Dialog>

      
    </>
  );
};

export default Terminal;
