import {combineReducers} from "redux"
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, PERSIST,REGISTER, FLUSH, REHYDRATE, PAUSE, PURGE, } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {dataReducers} from "./data";
import { authReducers } from "./authentication";




const rootReducer=combineReducers({
  data:dataReducers,
  auth:authReducers
})

const persistConfig = {
    key: 'root',
    storage,
    // autoMergeLevel2: true,
  }
   
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: 
      {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: {
        ignoredPaths: ['items.data']
      }
    }),
  
  });
  
  export let persistor = persistStore(store);
 
