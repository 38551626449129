import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   loginInfo:{}
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginDetail: (state,actions) => {
      state.loginInfo = actions.payload
    },
  


   
  },
})

 export const { loginDetail } = authSlice.actions

export const authReducers= authSlice.reducer