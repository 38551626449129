import { Button, Divider, Grid2, Paper, Typography,TextField,Select,InputLabel,Box, List, ListItem, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { commonCss } from '../../../StyleConfig/CommonCss'
import { ArrowBack,DeleteOutline , ArrowDownwardRounded, ArrowUpwardRounded, FileUploadOutlined, KeyboardArrowDown, KeyboardArrowUp, Visibility, EditOutlined, Add } from '@mui/icons-material'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

import moment from "moment"
import NormalTable from '../../../Component/NormalTable';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { excavationAttach } from '../../../Redux/data';
import { Form, Formik, validateYupSchema } from 'formik';
import dayjs from 'dayjs';
import Loading from '../../../Component/Loading';
import { axiosPrivate } from '../../../axios/axios';
import { api } from '../../API/config';
import { toast } from 'react-toastify';

const Excavation = ({handleCloseClick,certificateDetail,location}) => {
  const [searchParams,setSearchParams]=useSearchParams()
  const [certificateInfo,setCertificateInfo]=useState({})
  const login = useSelector((state) => state.auth.loginInfo);

  const [loading,setLoading]=useState(false)
  
  const [attachment,setAttachment]=useState(false)
  const [addPrecautionData,setAddPrecautionData]=useState([])
  const [addPrecaution,setAddPrecaution]=useState("")
  const [editPrecaution,setEditPrecaution]=useState({bol:false,i:null})
  const [approveInfo,setApproveInfo]=useState({
    engineerName:certificateInfo.engineerName,
    lpiName:certificateInfo.lpiName,
    certificateClosedName:certificateInfo.certificateClosedName
  })

  const dispatch=useDispatch()
  const navigate=useNavigate()

  useEffect(()=>{
   getData()
  },[])

  const getData=async()=>{
    setLoading(true)
  const getDataLink=api.excavation.getById
  await axiosPrivate.get(`${getDataLink}/${certificateDetail.id}`).then(res=>{
    console.log(res)
  setCertificateInfo(res.data.data)
  console.log(res.data.data.additionalPrecautions===null?[]:String(res.data.data.additionalPrecautions).split(","))
  setAddPrecautionData(res.data.data.additionalPrecautions===null?[]:String(res.data.data.additionalPrecautions).split(","))
 
  
  
  }).catch(e=>{
    setCertificateInfo({})
    setLoading(false)
    console.log(e)
  })
  setLoading(false)
  }

  const initialValue={
    id: certificateInfo.id,
    formId: certificateInfo.formId,
    representativeId: certificateInfo.representativeId!==null?certificateInfo.representativeId:login.userId,
  locationText: location ,
  excavationDate:certificateInfo.excavationDate!==null?certificateInfo.excavationDate:moment().utc(),
  additionalPrecautions: String(certificateInfo.additionalPrecautions).split(","),
  attachments:certificateInfo.attachments!==null? String(certificateInfo.attachments).split("|"):[],
    engineerId: certificateInfo.engineerId,
    isEngineerApproved: certificateInfo.isEngineerApproved,
    engineerApprovedDate: certificateInfo.engineerApprovedDate,
    engineerRemarks: certificateInfo.engineerRemarks,
    isCertificateClosed: certificateInfo.isCertificateClosed,
    certificateClosedBy: certificateInfo.certificateClosedBy,
    certificateClosedAt: certificateInfo.certificateClosedAt,
    certificateClosedReason: certificateInfo.certificateClosedReason,
    lpiId: certificateInfo.lpiId,
    isLPIApproved: certificateInfo.isLPIApproved,
    lpiApprovedDate: certificateInfo.lpiApprovedDate,
    lpiRemarks: certificateInfo.lpiRemarks,
    certificateValidFrom: certificateInfo.certificateValidFrom,
    certificateValidTo: certificateInfo.certificateValidTo,
    }

    
  const instructionsList=[
    "Piping and electrical/instruments must be localised by hand digging (with spades).",
    "Identified cabling and piping must be marked with wooden pegs.",
     "Piping and Cabling may have moved from its original location, as indicated on as-built drawings, due to soil statement.",
     "Within 1-meter radius around piping and cabling, only hand digging with spades may be used. ",
     "Excavations deeper than 1.2 meters require slopes of max. 70 degrees or side supporting structures to prevent collapse.",
     "Use of digging machinery is allowed: Yes/No (A Gas Test Certificate – Hot work is required).", 
     "Exposed piping and cabling to be supported.",
     "Workers maintain safe distance while equipment is in operation." ,
     "Spoil pile must be at a minimum of 0.75 meters from the edge of the excavation.", 
     "Excavations 6 meters or deeper require the services of a specialized Engineer.", 
     "The handling of contaminated soil requires the use of PPE as defined on the Permit to Work.",
     "Before/during excavation application, contractor MUST refer to the owner/specialist which familiar to the specific site.(ROW and Land Exec).",
     "Utilities mapping/ as built drawing/ excavation drawing MUST be attached with the certificate.",
     "Pipeline exposure more than a day must be protected and surrounded by sandbag and guarded overnight.",


  ]
  const columns= [
  
    {
      
      accessor: "name",
      // disableFilters: true,
    },
    {
      accessor:"id",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
            <IconButton onClick={()=>{
              // window.open(`/image?index=${cell.row.original.data}`)
            }} >
              <Visibility sx={{color:commonCss.red}}/>
            </IconButton>
            <IconButton >
              <DeleteOutline sx={{color:commonCss.red}}/>
            </IconButton>
           
           
          </Box>
        );
      },
    }
    
  ]
  const precautionColumns= [
    {
      accessor: "no",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
            {Number(cell.row.id)+1}
          </Typography>
        );
      },
      // disableFilters: true,
    }, 
    {
      accessor: "name",
      // disableFilters: true,
    },
    {
      accessor:"id",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
          
            <IconButton onClick={()=>{
              setEditPrecaution({bol:true,i:cell.row.id})
              setAddPrecaution(cell.row.original.name)
            }} >
              <EditOutlined sx={{color:commonCss.red}}/>
            </IconButton>
           
           
          </Box>
        );
      },
    }
    
  ]
  const handlePrecaution=()=>{
    if(editPrecaution.bol){
     let update=[...addPrecautionData] 
     update[editPrecaution.i]={name:addPrecaution}
     setAddPrecautionData(update)
     setEditPrecaution({bol:false,i:null})
     setAddPrecaution("")
    }else{
     setAddPrecautionData([...addPrecautionData,{name:addPrecaution}])
     setAddPrecaution("")
    }
 }

 const onSubmit=async(value)=>{
  console.log(addPrecautionData)
  const filterPrecaution=[]
  for(let i=0;addPrecautionData.length>i;i++){
    filterPrecaution.push(addPrecautionData[i].name)
  }
 const postData={
  ...value,
  attachments:value.attachments.join("|"),
  additionalPrecautions:filterPrecaution.toString()
 }
 console.log(postData)
 const editLink=api.excavation.edit
 await axiosPrivate.put(`${editLink}/${certificateInfo.id}`,postData).then(res=>{
  getData()
  toast.success("Successfully Updated")
 }).catch(e=>{
  console.log(e)
 })

 }
  return (
    <>
    {!loading?
    <Formik initialValues={initialValue} onSubmit={onSubmit} enableReinitialize>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
    <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 container size={12} alignItems={"center"}>
         <Grid2 container size={4} >
        <Box
          sx={{
            backgroundColor: commonCss.shadeGreen,
            display:"flex",
            alignItems:"cente",
            p:1,
            cursor:"pointer"
          }}
          onClick={handleCloseClick}
        >
          <ArrowBack color={commonCss.green} />
        </Box>
        </Grid2>
        <Grid2 container size={4} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
          Excavation Certificate
          </Typography>
        </Grid2>
        <Grid2 container size={4} justifyContent={"flex-end"}>
        <Box
          sx={{
            backgroundColor: commonCss.shadeGreen,
            p: 1,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            Permit To Work No. <span style={{ color: "#e53e3e" }}>{searchParams.get("no")}</span>
          </Typography>
        </Box>
        </Grid2>
        <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%' }} />
        </Grid2>
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={12} >
                    <Typography  variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red} >
                    DIGGING INSTRUCTIONS TO THE PERMIT APPLICANT 
                    </Typography>
        </Grid2>
        <Grid2 size={12} >
          <List sx={{ listStyleType: 'disc',pl: 2 }}>
            {instructionsList.map((listInst,i)=>(
              <ListItem sx={{ display: 'list-item',pl:0 }}>
            <Typography  variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none",lineHeight:1.5}}>
             {listInst}
            </Typography>
            </ListItem>
            ))}
            
          </List>
          
        </Grid2>
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12} >
            <Paper elevation={3}
             sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"} alignItems={"center"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          PSP REPRESENTATIVE ON SITE WHILE EXCAVATION IS IN PROGRESS
          </Typography>
          </Grid2 >
        <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Name
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={login.firstName+" "+login.lastName}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Location
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={values.locationText}
              disabled={true}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={dayjs(values.excavationDate)}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                  onChange={(newValue) => {
                    console.log(newValue);
                    if (newValue !== null) {
                      if (`${newValue.$d}` !== "Invalid Date") {
                        const dateFrom = moment(newValue.$d).utc()
                        setFieldValue("excavationDate",dateFrom)
                        // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                        // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                        // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                        // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                        // setFieldValue("installationDate", dateFormat);
                      }
                    }
                  }}
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
          
        <Grid2 size={{xs:12}} >
        
          <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
            I have checked the site/studied the layout drawings and certify that the excavation proposed under Permit to Work number <span style={{color:commonCss.red}}>{searchParams.get("no")}</span>  dated <span style={{color:commonCss.red}}>{moment(values.excavationDate).format("DD-MM-YYYY")}</span> can be carried out: 
          </Typography>
            
          </Grid2>
          <Grid2 size={{xs:12}} >
        
          <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
          a.	 Without risk of damage to any underground services
          </Typography>
            
          </Grid2>
          <Grid2 size={{xs:12}} >
        
          <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
          b.	 Provided that the following additional precautions are taken to prevent damages to the equipment/services specified below:
          </Typography>
            
          </Grid2>
          <Grid2 size={{xs:8}}>
          <Box sx={{display:"flex",gap:"1rem",alignItems:'center'}}>
              <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={addPrecaution}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={(e)=>{setAddPrecaution(e.target.value)}}
            />
              <IconButton sx={{border:`3px solid ${commonCss.red}`,p:0}} onClick={handlePrecaution} >
            <Add sx={{color:commonCss.red}}/>
         </IconButton>
              </Box>
          </Grid2>
         
         
          <Grid2 size={8} sx={{display:addPrecautionData.length!==0?"default":"none"}}>
            <Paper elevation={4} >
               {/* <Collapse in={labour} > */}
                <NormalTable data={addPrecautionData} columns={precautionColumns} hideHeader={true} />
               {/* </Collapse > */}
               </Paper>
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Situation Sketch/Indication of Hazards
              </Typography>
            </InputLabel>
            <Box>
              <Paper sx={{background:commonCss.green,display:"flex",gap:"0.5rem",width:'100%',justifyContent:"space-between",alignItems:"center",p:1}}>
                <Box sx={{display:"flex",alignItems:"center"}}>
              {/* <IconButton onClick={()=>{setAttachment(!attachment)}} >
                    {attachment?<KeyboardArrowUp sx={{color:"white"}}/>:<KeyboardArrowDown sx={{color:"white"}}/>}
                  </IconButton> */}
                <Typography sx={{color:"white",}}>
                  
                  Attachment
                </Typography>
                </Box>
                <IconButton component="label" sx={{background:commonCss.red}}>
                  <FileUploadOutlined sx={{color:"white"}}/>
                  <input
                    type="file"
                    accept={"image/*"}
                    hidden
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      if (e.target.files[0] !== undefined) {
                        const file = e.target.files[0];
                        console.log(file.name);
                        const fileType = file.type.split("/")[1];
                        console.log(fileType);
                        // if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {

                        //   toast.error("Invalid format");
                        // } else {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          // console.log(reader.result);
                          const dataSplit = reader.result.split("base64,")[1];
                          setFieldValue("attachments",[...values.attachments,reader.result])
                         
                        };
                        reader.onerror = function (error) {};
                        // }
                      } else {

                      }
                    }}
                  />
                </IconButton>
              </Paper>
              <Box sx={{background:"white",p:1,display:"default"}}>
              {values.attachments.map((att,i)=>(
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
                  <Typography>{i+1}</Typography>
                  {/* <Typography>{att}</Typography> */}
                  <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
            <IconButton onClick={()=>{
              window.open(`/image?index=${att}`)
            }} >
              <Visibility sx={{color:commonCss.red}}/>
            </IconButton>
            <IconButton >
              <DeleteOutline sx={{color:commonCss.red}}/>
            </IconButton>
           
           
          </Box>
                </Box>

              ))}
              </Box>
            </Box>
           
          </Grid2>
          
        </Grid2>
        
            </Paper>
        </Grid2>
       
        <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
         <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                  textAlign={"center"}
                >
                  Engineer
                </Typography>
                <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%',mt:1 }} />
              </Grid2>
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                  I certify that I have reviewed the proposed work and I am
                  satisfied that it is adequately described on the permit to
                  work and that the precautions detailed are adequate and
                  clearly defined
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }} >
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={approveInfo.engineerName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.isEngineerApproved?dayjs(values.engineerApprovedDate):null}
                    readOnly
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isEngineerApproved}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isEngineerApproved",true)
              setFieldValue("engineerId",login.userId)
              setFieldValue("engineerApprovedDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                engineerName:login.firstName+" "+login.lastName
              })
            }}
          >
            Approve
          </Button>
            </Grid2>
        </Grid2>
           </Paper>
        </Grid2>
        <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
          <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                  textAlign={"center"}
                >
                  LPI
                </Typography>
                <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%',mt:1 }} />
              </Grid2>
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Excavation Certificate is described in the permit to work is allowed
                  between:
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={approveInfo.lpiName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    readOnly
                    value={values.isLPIApproved?dayjs(values.lpiApprovedDate):null}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  From
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.certificateValidFrom!==null?dayjs(values.certificateValidFrom):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                            "YYYY-MM-DDTHH:mm:ss"
                          );
                          setFieldValue("certificateValidFrom",dateFrom)
                          
                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          // setFieldValue("installationDate", dateFormat);
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Until
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.certificateValidTo!==null?dayjs(values.certificateValidTo):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                            "YYYY-MM-DDTHH:mm:ss"
                          );
                          setFieldValue("certificateValidTo",dateFrom)
                          
                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          // setFieldValue("installationDate", dateFormat);
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isLPIApproved}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isLPIApproved",true)
              setFieldValue("lpiId",login.userId)
              setFieldValue("lpiApprovedDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                lpiName:login.firstName+" "+login.lastName
              })
            }}
          >
            Approve
          </Button>
            </Grid2>
            </Grid2>
          </Paper>
        
         </Grid2>
        <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
          <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 item xs={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Excavation Certificate is certificate and permit to work are
                  withdrawn:
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
                value={values.isCertificateClosed!==null?approveInfo.certificateClosedName:null}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    readOnly
                    value={values.isCertificateClosed?dayjs(values.certificateClosedAt):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Reason
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="certificateClosedReason"
                value={values.certificateClosedReason}
                onChange={handleChange}
                multiline
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isCertificateClosed}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isCertificateClosed",true)
              setFieldValue("certificateClosedBy",login.userId)
              setFieldValue("certificateClosedAt",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                certificateClosedName:login.firstName+" "+login.lastName
              })
            }}
          >
            Close
          </Button>
            </Grid2>
            </Grid2>
            </Paper>
         
        </Grid2>
        
        <Grid2 container size={12} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            type='submit'
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"10vw"
            }}
            // onClick={()=>{setLabour(true)}}
          >
           Submit
          </Button>
        </Grid2>
     </Grid2>
     </Form>
        )}

        </Formik>
        :<Loading/>}
        </>
  )
}

export default Excavation