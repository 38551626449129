export const roleInfo = {
  agt: "605ba1bc-fffa-4032-e1c6-08dcde22af85",
  technican: "48692a51-30fb-449b-1591-08dcdcc6195b",
  pi: "f8ff551f-93ba-48d4-5326-08dce068874f",
  lpi: "97b4e6b5-5175-4774-5329-08dce068874f",
  engineer: "cc6cbbb6-f9ae-4dc8-5325-08dce068874f",
};
export const terminalInfo = {
  terminal1: "058f18e7-d04e-48dc-bbca-5b23be6a0717",
  terminal2: "0471f94b-f8aa-4669-afde-722d33721ce6",
  terminal3: "058f18e7-d04e-48dc-bbca-5b23be6a0717",
};
export const companyInfo = {
  ps: "f6b006ef-b07b-4b79-8b29-ebd6b4bee7af",
};
export const userTypeInfo={
 internal:"a6df7d9a-8dcb-4c7a-957a-2a85df21d01f"
}
export const infoId = {
  low: "5a07c230-a3da-4af8-b2b9-9fe369942bed",
  classifiedAreaWithWhatOtherId: "b146835f-cf3d-4ffc-b582-a9f4a4639a5d",
  operationalInformationOtherId: "80600331-3e11-428d-9e96-9169e6fe8a89",
};
export const api = {
  account: {
    login: "/Account/authenticate",
  },
  PTWForm: {
    getAllForm: "/PTWForm/GetFormList",
    formInitiate: "/PTWForm/InitiatePTWForm",
    saveForm: "/PTWForm/SavePTWForm",
    getDetailById: "/PTWForm/GetPTWFormDetail",
  },
  terminal: {
    title: "Terminal",
    getAll: "/TerminalMaster/GetAll",
    add: "/TerminalMaster/Add",
    edit: "/TerminalMaster/Edit",
  },
  site: {
    title: "Site",
    getAll: "/SiteMaster/GetAll",
    add: "/SiteMaster/Add",
    edit: "/SiteMaster/Edit",
  },
  role: {
    title: "Role",
    getAll: "/RoleMaster/GetAll",
    add: "/RoleMaster/Add",
    edit: "/RoleMaster/Edit",
    getByUserType: "/RoleMaster/GetAllRolesByUserType",
  },
  company: {
    title: "Company",
    getAll: "/CompanyMaster/GetAll",
    add: "/CompanyMaster/Add",
    edit: "/CompanyMaster/Edit",
  },
  user: {
    title: "User",
    getAll: "/UserMaster/GetAll",
    add: "/UserMaster/Add",
    edit: "/UserMaster/Edit",
    getUserByCompanyId: "/UserMaster/GetUserByCompanyId",
    getUserByCompanyIdAndRoleId: "/UserMaster/GetCompanyUserByRoleId",
  },
  userType: {
    getAll: "/UserTypeMaster/GetAll",
  },
  hotWork: {
    getByHotWorkId: "/PTWHotWorkGasTest/GetAllByHotWorkId",
    addGasTest: "/PTWHotWorkGasTest/Add",
    editGasTest: "/PTWHotWorkGasTest/Edit",
    getDataByFormId: "/PTWHotWorkCertificate/GetAllByFormId",
    editHotWork: "/PTWHotWorkCertificate/Edit",
    getById: "/PTWHotWorkCertificate/Get",
  },
  confinedSpace: {
    getByCSId: "/PTWConfinedSpaceGasTest/GetAllByConfinedSpaceId",
    addGasTest: "/PTWConfinedSpaceGasTest/Add",
    editGasTest: "/PTWConfinedSpaceGasTest/Edit",
    getDataByFormId: "/PTWConfinedSpaceCertificate/GetAllByFormId",
    editCS: "/PTWConfinedSpaceCertificate/Edit",
    getById: "/PTWConfinedSpaceCertificate/Get",
  },
  excavation: {
    getDataByFormId: "/PTWExcavationCertificate/GetAllByFormId",
    getById: "/PTWExcavationCertificate/Get",
    edit: "/PTWExcavationCertificate/Edit",
  },
  systemOverriding: {
    getDataByFormId: "/PTWSystemOverridingCertificate/GetAllByFormId",
    getById: "/PTWSystemOverridingCertificate/Get",
    edit: "/PTWSystemOverridingCertificate/Edit",
  },
  isolation: {
    getDataByFormId: "/PTWIsolationCertificate/GetAllByFormId",
    getById: "/PTWIsolationCertificate/Get",
    edit: "/PTWIsolationCertificate/Edit",
  },
  liftingAndHoisting: {
    getDataByFormId: "/PTWLiftingAndHoistingCertificate/GetAllByFormId",
    edit: "/PTWLiftingAndHoistingCertificate/Edit",
    getById: "/PTWLiftingAndHoistingCertificate/Get",
  },
  radiation: {
    getDataByFormId: "/PTWRadiationCertificate/GetAllByFormId",
    edit: "/PTWRadiationCertificate/Edit",
    getById: "/PTWRadiationCertificate/Get",
  },
  attributeValue: {
    getByKeyName: "/AttributeValue/GetAllByKeyName",
  },
  formType: {
    getAll: "/PTWFormTypeMaster/GetAll",
   },
    methodstatement: {
        create: "/PTWMethodStatement/Add",
        getByFormId: "/PTWMethodStatement/GetAllByFormId",
        edit: "/PTWMethodStatement/Edit",
    },
  additionalPrecaution: {
    getByFormId: "/PTWAdditionalPrecaution/GetAllByFormId",
    add: "/PTWAdditionalPrecaution/Add",
    edit: "/PTWAdditionalPrecaution/Edit",
  },
  location:{
    getAll:"/LocationMaster/GetAll"
  },
  riskAssessment:{
    getByFormId:"/PTWRiskAssessment/GetAllByFormId",
    add:'/PTWRiskAssessment/Add',
    edit:"/PTWRiskAssessment/Edit",
    getByRiskId:"/PTWRSHazardAnalysisWorksheet/GetAllByRiskAssessmentId",
    hazardAdd:"/PTWRSHazardAnalysisWorksheet/Add",
    hazardEdit:"/PTWRSHazardAnalysisWorksheet/Edit",
  }
};
