import { Button, Divider, Grid2, Paper, Typography,TextField,Select,InputLabel,Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { commonCss } from '../../../StyleConfig/CommonCss'
import { ArrowBack, FileUploadOutlined } from '@mui/icons-material'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

import LAHimage from "../../../Images/LAHimage.png"
import { useSelector } from 'react-redux';
import { axiosPrivate } from '../../../axios/axios';
import { toast } from 'react-toastify';
import { api } from '../../API/config';
import Loading from '../../../Component/Loading';
import { Form, Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import dayjs from 'dayjs';

const SafeOverride = ({handleCloseClick,certificateDetail}) => {
  const [searchParams,setSearchParams]=useSearchParams()
  const login = useSelector((state) => state.auth.loginInfo);
  const [certificateInfo,setCertificateInfo]=useState({})
  const [loading,setLoading]=useState(false)
  const [approveInfo,setApproveInfo]=useState({
    engineerName:certificateInfo.engineerName,
    lpiName:certificateInfo.lpiName,
    certificateClosedName:certificateInfo.certificateClosedName
  })

  useEffect(()=>{
    getData()
  
  },[])
  const getData=async()=>{
    setLoading(true)
  const getDataLink=api.systemOverriding.getById
  await axiosPrivate.get(`${getDataLink}/${certificateDetail.id}`).then(res=>{
    console.log(res)
  setCertificateInfo(res.data.data)
  setLoading(false)
  }).catch(e=>{
    setCertificateInfo({})
    setLoading(false)
    console.log(e)
  })
  }

  const initialValue={
    id: certificateInfo.id,
  formId: certificateInfo.formId,
  site: certificateInfo.site,
  deviceDetails: certificateInfo.deviceDetails,
  person1Details: certificateInfo.person1Details,
  person2Details: certificateInfo.person2Details,
  person3Details: certificateInfo.person3Details,
  operatingPrecautions: certificateInfo.operatingPrecautions,
  overrideFrom: certificateInfo.overrideFrom,
  overrideTo: certificateInfo.overrideTo,
  engineerId: certificateInfo.engineerId,
  isEngineerApproved: certificateInfo.isEngineerApproved,
  engineerApprovedDate: certificateInfo.engineerApprovedDate,
  isCertificateClosed: certificateInfo.isCertificateClosed,
  certificateClosedBy: certificateInfo.certificateClosedBy,
  certificateClosedAt: certificateInfo.certificateClosedAt,
  certificateClosedReason: certificateInfo.certificateClosedReason,
  lpiId: certificateInfo.lpiId,
  isLPIApproved: certificateInfo.isLPIApproved,
  lpiApprovedDate: certificateInfo.lpiApprovedDate,
  certificateValidFrom: certificateInfo.certificateValidFrom,
  certificateValidTo: certificateInfo.certificateValidTo
  }
  const onSubmit=async(value)=>{
    console.log(value)
   const editLink=api.systemOverriding.edit
  await axiosPrivate.put(`${editLink}/${certificateInfo.id}`,value).then(res=>{
    console.log(res)
  
    getData()
    toast.success("Successfully Updated")
  }).catch(e=>{
    console.log(e)
  })
  }
  return (
    <>
    {!loading?
    <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
    <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 container size={12} alignItems={"center"}>
         <Grid2 container size={3} >
        <Box
          sx={{
            backgroundColor: commonCss.shadeGreen,
            display:"flex",
            alignItems:"cente",
            p:1,
            cursor:"pointer"
          }}
          onClick={handleCloseClick}
        >
          <ArrowBack color={commonCss.green} />
        </Box>
        </Grid2>
        <Grid2 container size={6} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
          Safeguarding Systems Override Certificate
          </Typography>
        </Grid2>
        <Grid2 container size={3} justifyContent={"flex-end"}>
        <Box
          sx={{
            backgroundColor: commonCss.shadeGreen,
            p: 1,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            Permit To Work No. <span style={{ color: "#e53e3e" }}>{searchParams.get("no")}</span>
          </Typography>
        </Box>
        </Grid2>
        <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%' }} />
        </Grid2>
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={12} >
                    <Typography  variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                    Override of safeguarding protective devices may utilize a standing procedure, the MOC process, or PTW process (including this certificate). 
                    </Typography>
        </Grid2>
        <Grid2 size={12} >
          <Typography  variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none",lineHeight:1.5}}>
          This PTW Override may not be utilized for the purpose of preventing an alarm trip associated with a known actual exceedence of an established operating parameter (pressure, flow, temperature, fill height, etc) for which the device is intended. It may be utilized for scheduled and planned overrides (typically for testing or maintenance) when the operating parameters are otherwise controlled within approved limits during the override period.
          </Typography>
        </Grid2>
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12} >
            <Paper elevation={3}
             sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"} alignItems={"center"}>
        
        <Grid2 size={{xs:12,md:6}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor} sx={{textWrap:"wrap"}}>
              Site (For safeguarding equipment overrides; not to be used for policy or procedural exceptions)
              </Typography>
            </InputLabel>
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
           <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={values.site}
              name="site"
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Identify the protective device/system including alarms that are to be overridden
              </Typography>
            </InputLabel>
          </Grid2> 
          <Grid2 size={{xs:12,md:6}}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={values.deviceDetails}
              name="deviceDetails"
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Name of person responsible for the override while it in place
              </Typography>
            </InputLabel>
          </Grid2> 
          <Grid2 size={{xs:12,md:6}}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={values.person1Details}
              name="person1Details"
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Name of person who will assure that the protective device or system has been properly returned to correct operating condition upon completion of the override:
              </Typography>
            </InputLabel>
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={values.person2Details}
              name="person2Details"
              onChange={handleChange}
              multiline
              rows={2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
           </Grid2>
           <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Name of person who will assure that Operating personnel are made aware that the override is completed and the device is ready for normal service
               </Typography>
            </InputLabel>
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={values.person3Details}
              name="person3Details"
              onChange={handleChange}
              multiline
              rows={2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
           </Grid2>
          
          
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:'100%'}}
            >
         
        <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor} lineHeight={1.5}>
              The risks of the override must be evaluated and sufficient barriers put in place to prevent unintended consequences related to the override. Communication prior to and after the override is critically important. This PTW and certificate are intended to put in place such communications and any other barriers identified to prevent unintended consequences related to the override during the period of and after its’ implementation. Use the space below to document any additional barriers not already covered elsewhere in this PTW.
              </Typography>
            </InputLabel>
          </Grid2>
          <Grid2 size={{xs:12}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Operating precautions (people and equipment barriers) including measures to prevent a related process upset condition while the override is in place and the plans including communications for return to normal service:
              </Typography>
            </InputLabel>
          </Grid2>
          <Grid2 size={{xs:12}}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={values.operatingPrecautions}
              name="operatingPrecautions"
              onChange={handleChange}
              multiline
              maxRows={5}
              minRows={2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"} alignItems={"center"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          Planned Duration of Over ride
          </Typography>
          </Grid2 >
         <Grid2 size={{xs:12,md:6}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Over rides extending beyond a single shift must have the PTW for the override re-issued at the beginning of the next shift. Longer term overrides (lasting into a weekend) should utilize the MOC process.
              </Typography>
            </InputLabel>
          </Grid2>
          <Grid2 container size={{xs:12,md:6}}>
            <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              From
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={values.overrideFrom!==null?dayjs(values.overrideFrom):null}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  onChange={(newValue) => {
                    console.log(newValue);
                    if (newValue !== null) {
                      if (`${newValue.$d}` !== "Invalid Date") {
                        const dateFrom = moment(newValue.$d).utc()
                        setFieldValue("overrideFrom",dateFrom)
                        
                        // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                        // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                        // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                        // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                        // setFieldValue("installationDate", dateFormat);
                      }
                    }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
            </Grid2>
            <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Until
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={values.overrideTo!==null?dayjs(values.overrideTo):null}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  onChange={(newValue) => {
                    console.log(newValue);
                    if (newValue !== null) {
                      if (`${newValue.$d}` !== "Invalid Date") {
                        const dateFrom = moment(newValue.$d).utc()
                        setFieldValue("overrideTo",dateFrom)
                        
                        // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                        // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                        // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                        // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                        // setFieldValue("installationDate", dateFormat);
                      }
                    }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
            </Grid2>
          </Grid2>
       </Grid2>
        </Paper>
        </Grid2>
        <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
         <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                  textAlign={"center"}
                >
                  Engineer
                </Typography>
                <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%',mt:1 }} />
              </Grid2>
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                  I certify that I have reviewed the proposed work and I am
                  satisfied that it is adequately described on the permit to
                  work and that the precautions detailed are adequate and
                  clearly defined
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }} >
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={approveInfo.engineerName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.isEngineerApproved?dayjs(values.engineerApprovedDate):null}
                    readOnly
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isEngineerApproved}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isEngineerApproved",true)
              setFieldValue("engineerId",login.userId)
              setFieldValue("engineerApprovedDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                engineerName:login.firstName+" "+login.lastName
              })
            }}
          >
            Approve
          </Button>
            </Grid2>
          
        </Grid2>
           </Paper>
        </Grid2>
        <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
          <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                  textAlign={"center"}
                >
                  LPI
                </Typography>
                <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%',mt:1 }} />
              </Grid2>
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Safeguarding Systems Override Certificate is described in the permit to work is allowed
                  between:
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={approveInfo.lpiName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    readOnly
                    value={values.isLPIApproved?dayjs(values.lpiApprovedDate):null}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  From
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.certificateValidFrom!==null?dayjs(values.certificateValidFrom):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                            "YYYY-MM-DDTHH:mm:ss"
                          );
                          setFieldValue("certificateValidFrom",dateFrom)
                          
                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          // setFieldValue("installationDate", dateFormat);
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Until
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.certificateValidTo!==null?dayjs(values.certificateValidTo):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                            "YYYY-MM-DDTHH:mm:ss"
                          );
                          setFieldValue("certificateValidTo",dateFrom)
                          
                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          // setFieldValue("installationDate", dateFormat);
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isLPIApproved}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isLPIApproved",true)
              setFieldValue("lpiId",login.userId)
              setFieldValue("lpiApprovedDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                lpiName:login.firstName+" "+login.lastName
              })
            }}
          >
            Approve
          </Button>
            </Grid2>
            </Grid2>
          </Paper>
        
         </Grid2>
        <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
          <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 item xs={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Safeguarding Systems Override Certificate is certificate and permit to work are
                  withdrawn:
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
                value={values.isCertificateClosed!==null?approveInfo.certificateClosedName:null}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    readOnly
                    value={values.isCertificateClosed?dayjs(values.certificateClosedAt):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Reason
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="certificateClosedReason"
                value={values.certificateClosedReason}
                onChange={handleChange}
                multiline
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isCertificateClosed}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isCertificateClosed",true)
              setFieldValue("certificateClosedBy",login.userId)
              setFieldValue("certificateClosedAt",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                certificateClosedName:login.firstName+" "+login.lastName
              })
            }}
          >
            Close
          </Button>
            </Grid2>
            </Grid2>
            </Paper>
         
        </Grid2>
       
        <Grid2 container size={12} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"10vw"
            }}
            // onClick={()=>{setLabour(true)}}
          >
           Submit
          </Button>
        </Grid2>
     </Grid2>
     </Form>
        )}

        </Formik>
        :<Loading/>}
        </>
  )
}

export default SafeOverride