import {
  Button,
  Divider,
  Grid2,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  InputLabel,
  FormGroup,
  FormControlLabel,
  IconButton,
  Box,
  createFilterOptions,
  Autocomplete,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { commonCss } from "../../../StyleConfig/CommonCss";
import { ArrowBack, FileUploadOutlined } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import TableSample from "../../../Component/TableSample";
import NormalTableWithRadius from "../../../Component/NormalTableWithRadius";
import { EditOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { api, companyInfo, roleInfo } from "../../API/config";
import { axiosPrivate } from "../../../axios/axios";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import Loading from "../../../Component/Loading";
import { useSearchParams } from "react-router-dom";
import GasTestComponent from "./GasTestComponent";

const ConfinedSpaceCertificate = ({ handleCloseClick,certificateDetail }) => {
  const [searchParams,setSearchParams]=useSearchParams()
  const [certificateInfo,setCertificateInfo]=useState({})
  const login = useSelector((state) => state.auth.loginInfo);

  const [agt,setAgt]=useState({agtBol:certificateInfo.agtId!==null?true:false,agtType:"",agtCom:"",agtId:certificateInfo.agtId})
  const [agtData,setAgtData]=useState([])
  const [loading,setLoading]=useState(false)
  const [editGas, setEditGas] = useState({ bol: false, data: {} });
  const [gasData, setGasData] = useState([]);
  const [approveInfo,setApproveInfo]=useState({
    engineerName:certificateInfo.engineerName,
    lpiName:certificateInfo.lpiName,
    certificateClosedName:certificateInfo.certificateClosedName
  })
  const [measureMethod,setMeasureMethod]=useState({
    oxygenMethodOfMeasurements:"",
oxygenValue:"",
explosiveVapourMethodOfMeasurements:"",
explosiveVapourValue:"",
asphyxiantVapourMethodOfMeasurements:"",
asphyxiantVapourValue:"",
poisonousVapourMethodOfMeasurements:"",
poisonousVapourValue:"",
  })
  const [classifiedWhere,setClassifiedWhere]=useState([])
  const [classifiedWheresecond,setclassifiedWheresecond]=useState([]) 
  const [classifiedWherethrid,setClassifiedWherethrid]=useState([])

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };
  useEffect(()=>{
    
   
    
    getData()
  
  },[])
  const getDataCS=async()=>{
    const getGasDataLink=api.confinedSpace.getByCSId
    await axiosPrivate.get(`${getGasDataLink}?confinedSpaceId=${certificateDetail.id}`).then(res=>{
      setGasData(res.data.data)
    }).catch(e=>{
      console.log(e)
      setGasData([])
    })
  }
  const getData=async()=>{
    setLoading(true)
  const getDataLink=api.confinedSpace.getById
  await axiosPrivate.get(`${getDataLink}/${certificateDetail.id}`).then(res=>{
    console.log(res)
  setCertificateInfo(res.data.data)
  setAgt({agtBol:res.data.data?.agtId!==null?true:false,agtType:res.data.data.agtUserDetail?.companyId,agtCom:res.data.data.agtId!==null?res.data.data.agtUserDetail?.firstName+" "+res.data.data.agtUserDetail?.lastName:"",agtId:res.data.data.agtId})

  setMeasureMethod({
    oxygenMethodOfMeasurements:res.data.data.oxygenMethodOfMeasurements,
oxygenValue:res.data.data.oxygenValue,
explosiveVapourMethodOfMeasurements:res.data.data.explosiveVapourMethodOfMeasurements,
explosiveVapourValue:res.data.data.explosiveVapourValue,
asphyxiantVapourMethodOfMeasurements:res.data.data.asphyxiantVapourMethodOfMeasurements,
asphyxiantVapourValue:res.data.data.asphyxiantVapourValue,
poisonousVapourMethodOfMeasurements:res.data.data.poisonousVapourMethodOfMeasurements,
poisonousVapourValue:res.data.data.poisonousVapourValue,
  })
  
  }).catch(e=>{
    setCertificateInfo({})
    setLoading(false)
    console.log(e)
  })
  const getAttributeValueLink=api.attributeValue.getByKeyName
  await axiosPrivate.get(`${getAttributeValueLink}?keyName=confinedSpaceCleanedUsing`).then(res=>{
    setClassifiedWherethrid(res.data.data)
  }).catch(e=>{
    setClassifiedWherethrid([])
  })
  await axiosPrivate.get(`${getAttributeValueLink}?keyName=personalProtectiveClothes`).then(res=>{
    setClassifiedWhere(res.data.data)
  }).catch(e=>{
    setClassifiedWhere([])
  })
  await axiosPrivate.get(`${getAttributeValueLink}?keyName=equipmentsUsed`).then(res=>{
    setclassifiedWheresecond(res.data.data)
  }).catch(e=>{
    setclassifiedWheresecond([])
  })
   await getDataCS()
   setLoading(false)
  }


  const gasColumns = [
    {
      Header: "O2",
      accessor: "o2",
    },

    {
      Header: "CO2",
      accessor: "cO2",
    },
    {
      Header: "H2S",
      accessor: "h2S",
    },
    {
      Header: "LEL",
      accessor: "lel",
    },
    {
      Header: "Date/Time",
      accessor: "agtApprovedDate",
    },
    {
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Box
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setEditGas({ bol: true, data: cell.row.original });
                
              }}
            >
              <EditOutlined sx={{ color: commonCss.red }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const initialValue={
  id: certificateDetail.id,
  formId: searchParams.get("id"),
  activity: certificateInfo.activity,
  fireAndExplosion: certificateInfo.fireAndExplosion,
  asphyxiation: certificateInfo.asphyxiation,
  poisioning: certificateInfo.poisioning,
  otherHazards: certificateInfo.otherHazards,
  locationText: certificateInfo.locationText,
  access: certificateInfo.access,
  noOfEntrance: certificateInfo.noOfEntrance,
  diameter: certificateInfo.diameter,
  height: certificateInfo.height,
  otherEntrances: certificateInfo.otherEntrances,
  description: certificateInfo.description,
  contents: certificateInfo.contents,
  howResiduesRemoved: certificateInfo.howResiduesRemoved,
  isFreeFromResidues: certificateInfo.isFreeFromResidues,
  personalProtectiveClothes:String(certificateInfo.personalProtectiveClothes).split(","),
  equipmentsUsed:String(certificateInfo.equipmentsUsed).split(","),
  confinedSpaceCleanedUsing:String(certificateInfo.confinedSpaceCleanedUsing).split(","),
  confinedSpaceCleanedUsingOthers: certificateInfo.confinedSpaceCleanedUsingOthers,
  // oxygenMethodOfMeasurements: certificateInfo.oxygenMethodOfMeasurements,
  // oxygenValue: certificateInfo.oxygenValue,
  // explosiveVapourMethodOfMeasurements: certificateInfo.explosiveVapourMethodOfMeasurements,
  // explosiveVapourValue: certificateInfo.explosiveVapourValue,
  // asphyxiantVapourMethodOfMeasurements: certificateInfo.asphyxiantVapourMethodOfMeasurements,
  // asphyxiantVapourValue: certificateInfo.asphyxiantVapourValue,
  // poisonousVapourMethodOfMeasurements: certificateInfo.poisonousVapourMethodOfMeasurements,
  // poisonousVapourValue: certificateInfo.poisonousVapourValue,
  testingFrequency: certificateInfo.testingFrequency,
  agtId: certificateInfo.agtId,
  isAGTApproved: certificateInfo.isAGTApproved,
  agtApprovedDate: certificateInfo.agtApprovedDate,
  agtRemarks: certificateInfo.agtRemarks,
  engineerId: certificateInfo.engineerId,
  isEngineerApproved: certificateInfo.isEngineerApproved,
  engineerApprovedDate: certificateInfo.engineerApprovedDate,
  engineerRemarks: certificateInfo.engineerRemarks,
  isCertificateClosed: certificateInfo.isCertificateClosed,
  certificateClosedBy: certificateInfo.certificateClosedBy,
  certificateClosedAt: certificateInfo.certificateClosedAt,
  certificateClosedReason: certificateInfo.certificateClosedReason,
  lpiId: certificateInfo.lpiId,
  isLPIApproved: certificateInfo.isLPIApproved,
  lpiApprovedDate: certificateInfo.lpiApprovedDate,
  lpiRemarks: certificateInfo.lpiRemarks,
  certificateValidFrom: certificateInfo.certificateValidFrom,
  certificateValidTo: certificateInfo.certificateValidTo,
  }

  const onSubmit=async(value)=>{
   
    const postData={
      ...value,
      personalProtectiveClothes:value.personalProtectiveClothes.toString(),
equipmentsUsed:value.equipmentsUsed.toString(),
confinedSpaceCleanedUsing:value.confinedSpaceCleanedUsing.toString(),
      ...measureMethod
    }
    console.log(postData)
   const editLink=api.confinedSpace.editCS
  await axiosPrivate.put(`${editLink}/${certificateInfo.id}`,postData).then(res=>{
    console.log(res)
  
    getData()
    toast.success("Successfully Updated")
  }).catch(e=>{
    console.log(e)
  })
  }
  const getAgt=async(companyId)=>{
    const getUserByCompanyAndRoleId=api.user.getUserByCompanyIdAndRoleId
    const roleId=roleInfo.agt
    console.log(companyId,roleId)
    await axiosPrivate.get(`${getUserByCompanyAndRoleId}?companyId=${companyId}&roleId=${roleId}`).then(res=>{
      setAgtData(res.data.data)
      console.log(res.data.data)
    }).catch(e=>{
      console.log(e)
      setAgtData([])
    })
  }
  const gasSubmit=async(gasValue)=>{
   console.log({...gasValue,confinedSpaceId:certificateInfo.id,agtId:agt.agtId})
   const postData={...gasValue,confinedSpaceId:certificateDetail.id,agtId:agt.agtId}
   const addLink=api.confinedSpace.addGasTest
   await axiosPrivate.post(`${addLink}`,postData).then(async(res)=>{
    console.log(res)
    await getDataCS()
   }).catch(e=>{
    console.log(e,"gas Test")
   })
  }

  const measureData = [
    {
      rowName: "Oxygen",
      MOM: "oxygenMethodOfMeasurements",
      momValue:measureMethod.oxygenMethodOfMeasurements,
      resultValue:measureMethod.oxygenValue,
      result: "oxygenValue",
    },
    {
      rowName: "Explosive vapour/gas, specify",
      MOM: "explosiveVapourMethodOfMeasurements",
      momValue:measureMethod.explosiveVapourMethodOfMeasurements,
      resultValue:measureMethod.explosiveVapourValue,
      result: "explosiveVapourValue",
    },
    {
      rowName: "Asphyxiant vapour/gas, specify",
      MOM: "asphyxiantVapourMethodOfMeasurements",
      momValue:measureMethod.asphyxiantVapourMethodOfMeasurements,
      resultValue:measureMethod.asphyxiantVapourValue,
      result: "asphyxiantVapourValue",
    },
    {
      rowName: "Poisonous vapour/gas, specify",
      MOM: "poisonousVapourMethodOfMeasurements",
      momValue:measureMethod.poisonousVapourMethodOfMeasurements,
      resultValue:measureMethod.poisonousVapourValue,
      result: "poisonousVapourValue",
    },
  ];
  const measureColumns = [
    {
      accessor: "rowName",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
            {cell.value}
          </Typography>
        );
      },
    },

    {
      Header: "Method of Measurement",
      accessor: "MOM",
      Cell: ({ cell }) => {
        const [value, setValue] = useState(cell.row.original.momValue);
        return (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name={cell.value}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              cell.row.original.momValue = e.target.value;
              
            }}
            onBlur={(e) => {
              setMeasureMethod({...measureMethod,[cell.value]:e.target.value});
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: commonCss.inputBackgroundColor,
                borderRadius: commonCss.inputBorderRadius,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: commonCss.inputBorderColor,
              },
            }}
            
            
          />
        );
      },
    },
    {
      Header: "Result",
      accessor: "result",
      Cell: ({ cell }) => {
        const [value, setValue] = useState(cell.row.original.resultValue);
        return (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name={cell.value}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              cell.row.original.resultValue = e.target.value;
            }}
            onBlur={(e) => {
              setMeasureMethod({...measureMethod,[cell.value]:e.target.value});
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: commonCss.inputBackgroundColor,
                borderRadius: commonCss.inputBorderRadius,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: commonCss.inputBorderColor,
              },
            }}
            
          />
        );
      }, 
    },
  ];

  return (
    <>
    {!loading?
    <Formik initialValues={initialValue} onSubmit={onSubmit} enableReinitialize>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
    <Grid2 container size={12} spacing={"1rem"}>
      <Grid2 container size={12} alignItems={"center"}>
        <Grid2 container size={4}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              display: "flex",
              alignItems: "cente",
              p: 1,
              cursor: "pointer",
            }}
            onClick={handleCloseClick}
          >
            <ArrowBack color={commonCss.green} />
          </Box>
        </Grid2>
        <Grid2 container size={4} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
            Confined Space Certificate
          </Typography>
        </Grid2>
        <Grid2 container size={4} justifyContent={"flex-end"}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              p: 1,
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              Permit To Work No. <span style={{ color: "#e53e3e" }}>{searchParams.get("no")}</span>
            </Typography>
          </Box>
        </Grid2>
        <Divider
          sx={{ borderColor: commonCss.red, borderWidth: 1, width: "100%" }}
        />
      </Grid2>
      <Grid2 container size={12} alignItems={"center"}>
        <Typography color="white" variant="body2">
          (To be completed only by authorised persons)
        </Typography>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 sx={{ mb: 2 }} size={{ xs: 12, md: 12 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Activity
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="activity"
                value={values.activity}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
              >
                HAZARDS
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Fire & Explosion
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="fireAndExplosion"
                value={values.fireAndExplosion}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Asphyxiation
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="asphyxiation"
                value={values.asphyxiation}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Poisioning
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="poisioning"
                value={values.poisioning}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Others
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="otherHazards"
                value={values.otherHazards}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
              >
                DESCRIBE THE CONFINED SPACE
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Location
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="locationText"
                value={values.locationText}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Access/egress
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="access"
                value={values.access}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Number of open entrances
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="noOfEntrance"
                value={values.noOfEntrance}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Diameter
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="diameter"
                value={values.diameter}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Height
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="height"
                value={values.height}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Other entrances
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="otherEntrances"
                value={values.otherEntrances}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Description
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="description"
                value={values.description}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Contents
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="contents"
                value={values.contents}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  How were the residues removed from confined space?
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="howResiduesRemoved"
                value={values.howResiduesRemoved}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Is this confined space now free of residues?
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="isFreeFromResidues"
                value={values.isFreeFromResidues}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"0.6rem"}>
          <Grid2 size={{ xs: 12, md: 4 }} >
              <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Company
                  </Typography>
                </InputLabel>
              <Select
                variant="outlined"
                size="small"
                defaultValue=""
                displayEmpty
                value={agt.agtType}
                onChange={(e)=>{
                  setAgt({...agt,agtType:e.target.value})
                  getAgt(e.target.value)
                }}
                fullWidth
                sx={{
                 
                  
                  borderRadius: "13px",
                  backgroundColor: "white",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "747373",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              >
                <MenuItem value={companyInfo.ps}>PS Pipeline</MenuItem>
                <MenuItem value={login.companyId}>{login.companyName}</MenuItem>
              </Select>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }} >
              <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    AGT
                  </Typography>
                  <Autocomplete
                  name="agtId"
                  inputValue={agt.agtCom}
                  filterOptions={filterOptions}
                  loading={agtData.length === 0 }
                  options={agtData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.firstName+" "+option.lastName || option}
                  onChange={(event, value) => {
                    if (value) {
                      setFieldValue("agtId",value.id)
                      setAgt({...agt,agtCom:value.firstName+" "+value.lastName,agtId:value.id});
                    } else {
                      setFieldValue("agtId",null)
                      setAgt({...agt,agtCom:"",agtId:null});
                    }
                  }}
                 
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="companyId"
                      size="small"
                      // variant="standard"
                      onChange={(e)=>{setAgt({...agt,agtCom:e.target.value})}}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: commonCss.inputBackgroundColor,
                          borderRadius: commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                      
                    />
                  )}
                />
                </InputLabel>
              </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date and Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.inputBackgroundColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Grid2 container direction="column" spacing={2}>
            <Grid2 item>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: commonCss.paperBorderRadius,
                  p: 1.5,
                  background: commonCss.shadeLinearGradient,
                  width: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                >
                  Personal Protective Clothes
                </Typography>
                <FormGroup>
                  {classifiedWhere.map((typeLevel, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={values.personalProtectiveClothes.includes(typeLevel.id)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "#00807F",
                            },
                            "&:checked": {
                              color: "#00807F",
                            },
                          }}
                          onChange={(e)=>{
                            if(e.target.checked){
                             setFieldValue("personalProtectiveClothes",[...values.personalProtectiveClothes,typeLevel.id])
                           }else{
                             const filterDataList=values.personalProtectiveClothes.filter(fil=>fil!==typeLevel.id)
                             setFieldValue("personalProtectiveClothes",filterDataList)
                           }
                          }}
                        />
                      }
                      label={typeLevel.name}
                    />
                  ))}
                </FormGroup>
              </Paper>
            </Grid2>

            <Grid2 item>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: commonCss.paperBorderRadius,
                  p: 1.5,
                  background: commonCss.shadeLinearGradient,
                  width: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                >
                  How was the confined space cleaned?
                </Typography>
                <FormGroup>
                  {classifiedWherethrid.map((typeLevel, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={values.confinedSpaceCleanedUsing.includes(typeLevel.id)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "#00807F",
                            },
                            "&:checked": {
                              color: "#00807F",
                            },
                          }}
                          onChange={(e)=>{
                            if(e.target.checked){
                             setFieldValue("confinedSpaceCleanedUsing",[...values.confinedSpaceCleanedUsing,typeLevel.id])
                           }else{
                             const filterDataList=values.confinedSpaceCleanedUsing.filter(fil=>fil!==typeLevel.id)
                             setFieldValue("confinedSpaceCleanedUsing",filterDataList)
                           }
                          }}
                        />
                      }
                      label={typeLevel.name}
                    />
                  ))}
                </FormGroup>
                <InputLabel sx={{ pl: 0.5 }}>
                  <Typography variant="body2" fontWeight="500" color="black">
                    Specify cleansing agent used
                  </Typography>
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="confinedSpaceCleanedUsingOthers"
                  value={values.confinedSpaceCleanedUsingOthers}
                  onChange={handleChange}
                  multiline
                  rows={2}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Paper>
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }}>
          <Paper
            elevation={3}
            sx={{
              borderRadius: commonCss.paperBorderRadius,
              p: 1.5,
              background: commonCss.shadeLinearGradient,
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              variant="body2"
              fontWeight={commonCss.mainLabelHeading}
              color={commonCss.red}
            >
              Equipment, emergency arrangement, etc...,
            </Typography>
            <FormGroup>
              {classifiedWheresecond.map((typeLevel, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      checked={values.equipmentsUsed.includes(typeLevel.id)}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "#00807F",
                        },
                        "&:checked": {
                          color: "#00807F",
                        },
                      }}
                      onChange={(e)=>{
                        if(e.target.checked){
                         setFieldValue("equipmentsUsed",[...values.equipmentsUsed,typeLevel.id])
                       }else{
                         const filterDataList=values.equipmentsUsed.filter(fil=>fil!==typeLevel.id)
                         setFieldValue("equipmentsUsed",filterDataList)
                       }
                      }}
                    />
                  }
                  label={typeLevel.name}
                />
              ))}
            </FormGroup>
          </Paper>
        </Grid2>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 sx={{ pl: 0.5, mb: 2 }}>
            <Typography
              variant="body2"
              fontWeight={commonCss.mainLabelHeading}
              color={commonCss.red}
            >
              Measurements before entering
            </Typography>
          </Grid2>
          <Grid2 size={12} mb={2}>
            <NormalTableWithRadius
              data={measureData}
              columns={measureColumns}
            />
          </Grid2>
          <Grid2 container direction="row" alignItems="center" spacing={2}>
            <InputLabel>
              <Typography variant="body2" fontWeight="500" color="black">
                Testing Frequency
              </Typography>
            </InputLabel>
            <TextField
              variant="outlined"
              size="small"
              name="testingFrequency"
              value={values.testingFrequency}
              onChange={handleChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: commonCss.inputBackgroundColor,
                  borderRadius: commonCss.inputBorderRadius,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: commonCss.inputBorderColor,
                },
                width: "85%",
              }}
            />
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12} >
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
              >
                Gas test performed
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
              >
                I confirm that I have tested the confined space for gas and/or
                oxygen and that the result are within the specified acceptance
                levels, and the measured concertation of explosive
                gasses/vapours is zero % LEL
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <GasTestComponent  edit={editGas.bol} editData={editGas.data}  onSubmitGas={gasSubmit}/>
            </Grid2>
            <Grid2
              size={{ xs: 12 }}
              sx={{ display: gasData.length === 0 ? "none" : "default" }}
            >
              <Paper sx={{ background: "transparent" }}>
                <NormalTableWithRadius
                  data={gasData}
                  columns={gasColumns}
                />
              </Paper>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
                textAlign={"center"}
              >
                Engineer
              </Typography>
              <Divider
                sx={{
                  borderColor: commonCss.red,
                  borderWidth: 1,
                  width: "100%",
                  mt: 1,
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                I certify that I have reviewed the proposed work and I am
                satisfied that it is adequately described on the permit to work
                and that the precautions detailed are adequate and clearly
                defined
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }} >
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={approveInfo.engineerName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.isEngineerApproved?dayjs(values.engineerApprovedDate):null}
                    readOnly
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isEngineerApproved}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isEngineerApproved",true)
              setFieldValue("engineerId",login.userId)
              setFieldValue("engineerApprovedDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                engineerName:login.firstName+" "+login.lastName
              })
            }}
          >
            Approve
          </Button>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
                textAlign={"center"}
              >
                LPI
              </Typography>
              <Divider
                sx={{
                  borderColor: commonCss.red,
                  borderWidth: 1,
                  width: "100%",
                  mt: 1,
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                Confined Space is described in the permit to work is allowed
                between:
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={approveInfo.lpiName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    readOnly
                    value={values.isLPIApproved?dayjs(values.lpiApprovedDate):null}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  From
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.certificateValidFrom!==null?dayjs(values.certificateValidFrom):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                            "YYYY-MM-DDTHH:mm:ss"
                          );
                          setFieldValue("certificateValidFrom",dateFrom)
                          
                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          // setFieldValue("installationDate", dateFormat);
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Until
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.certificateValidTo!==null?dayjs(values.certificateValidTo):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                            "YYYY-MM-DDTHH:mm:ss"
                          );
                          setFieldValue("certificateValidTo",dateFrom)
                          
                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          // setFieldValue("installationDate", dateFormat);
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isLPIApproved}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isLPIApproved",true)
              setFieldValue("lpiId",login.userId)
              setFieldValue("lpiApprovedDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                lpiName:login.firstName+" "+login.lastName
              })
            }}
          >
            Approve
          </Button>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 item xs={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                Confined Space certificate is certificate and permit to work are
                withdrawn:
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
                value={values.isCertificateClosed!==null?approveInfo.certificateClosedName:null}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    readOnly
                    value={values.isCertificateClosed?dayjs(values.certificateClosedAt):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Reason
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="certificateClosedReason"
                value={values.certificateClosedReason}
                onChange={handleChange}
                multiline
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isCertificateClosed}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isCertificateClosed",true)
              setFieldValue("certificateClosedBy",login.userId)
              setFieldValue("certificateClosedAt",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                certificateClosedName:login.firstName+" "+login.lastName
              })
            }}
          >
            Close
          </Button>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>

      <Grid2 container size={12} justifyContent={"flex-end"}>
        <Button
          variant="contained"
          type="submit"
          sx={{
            backgroundColor: commonCss.green,
            borderRadius: commonCss.buttonBorderRadius,
            textTransform: "none",
            padding: "0.6rem 1rem",
            color: commonCss.buttonTextColor,
            width: "10vw",
          }}
        >
          Submit
        </Button>
      </Grid2>
    </Grid2>
    </Form>
        )}

        </Formik>
        :<Loading/>}
        </>
  );
};

export default ConfinedSpaceCertificate;
