import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"

import {AppBar, Box, Icon, IconButton, MenuItem, Toolbar, Typography,Menu, Badge, Avatar,Drawer} from "@mui/material"
import {HelpOutline, NotificationsNoneOutlined, MenuRounded, Expand, ExpandMore, ExpandLess}from "@mui/icons-material"

import avatar from "../../Images/Nav/avatar.png"
import { useDispatch, useSelector } from 'react-redux'
import SideNav from './SideNav'
import { drawer } from '../../Redux/data'
import { commonCss } from '../../StyleConfig/CommonCss'



const Nav = (props) => {

  const navigate=useNavigate()
  const dispatch=useDispatch()
 
  const [anchorELNavQuestion,setAnchorElNavQues]=useState(null)
  const [anchorELNavimg,setAnchorElNavimg]=useState(null)
  const [open,setOpen]=useState(null)

  const drawerOpen = useSelector((state) => state.data.drawerOpen);

  const handleDrawerOpen = () => {
    props.drawerOpen()
   
  };

  const handleCloseNavMenu = () => {
    setAnchorElNavQues(null);
  };

  const handleArrowimg = (event) => {
    setAnchorElNavimg(event.currentTarget);
  };
  const handleCloseNavimg = () => {
    setAnchorElNavimg(null);
  };

  const handleArrow = (event) => {
    setAnchorElNavQues(event.currentTarget);
  };

  const handleBadgeLink=()=>{
    navigate("/welcome")
  }

  const handleOpen=()=>{
    if(drawerOpen.bol){
      dispatch(drawer({bol:drawerOpen.bol,open:!drawerOpen.open}))
    }else{
      setOpen(!open);
    }
  }

  return (
    <AppBar position="fixed" elevation={0} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 ,background:"#00807F"}}>
   <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%",p:"0.2rem 1.3rem"}}>
      <Box sx={{display:"flex",alignItems:"center",gap:"0.5rem"}}>
    <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{display:{xs:"none",md:"block"}}}
      >
        <MenuRounded sx={{fontSize:'2.2rem',color:"white"}} />
      </IconButton>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={()=>{handleOpen()}}
        edge="start"
        sx={{display:{xs:"block",md:"none"}}}
      >
        <MenuRounded sx={{fontSize:'2.2rem',color:"white"}} />
      </IconButton>
      <Typography variant="h6" noWrap  sx={{fontSize:"1.4rem",color:"#ffbe0b",mt:-1}}>
      <span style={{color:"white"}}>e</span> - PTW
      </Typography>
      </Box>
      <Box sx={{display:"flex",alignItems:"center",gap:"1.3rem"}}>
        {/* <IconButton onClick={handleArrow}>
          <HelpOutline sx={{color:"white"}}/>
        </IconButton>
        <Menu   anchorEl={anchorELNavQuestion}
          open={Boolean(anchorELNavQuestion)}
          onClose={handleCloseNavMenu}>
          <Typography sx={{color:"#00807F",fontSize:"14px",p:"8px 16px"}}>Header</Typography>
          <MenuItem>Another Action</MenuItem>
          <MenuItem>Another Action</MenuItem>
        </Menu> */}
        {/* <Badge badgeContent={10}  
        sx={{".MuiBadge-badge":{background:"#FD0000"},cursor:"pointer",}}
        onClick={handleBadgeLink}
        >
  <NotificationsNoneOutlined  sx={{color:'white'}} />
           </Badge> */}
<Box sx={{display:"flex",}}>
<Avatar alt="image" src={`${avatar}`}   sx={{ width: 34, height: 34,background:"white" }} />
<IconButton onClick={handleArrowimg}>{Boolean(anchorELNavimg)?<ExpandLess sx={{color:"white"}}/>: <ExpandMore sx={{color:"white"}}/>}</IconButton>
</Box>
<Menu   anchorEl={anchorELNavimg}
          open={Boolean(anchorELNavimg)}
          onClose={handleCloseNavimg}>
          {/* <Typography sx={{color:"#6C757D",fontSize:"14px",p:"8px 16px"}}>Header</Typography>
          <MenuItem>Another Action</MenuItem>
          <MenuItem>Another Action</MenuItem> */}
        <MenuItem
         onClick={()=>{
          // const signout=masterAPI["user"].signout
          // axiosPrivate.get(`${signout}`).then(res=>{
          //   dispatch({type:"logout",payload:{}})
          //   dispatch({type:"menuType",payload:[]})
          //   dispatch({type:"actionsAllow",payload:{}})
          //   navigate("/")
          // // dispatch({type:"logout",payload:false})
          // navigate("/")
          // }).catch(e=>{
          //   console.log(e,"error")
          // })
          // dispatch({type:"logout",payload:{}})
          // dispatch({type:"menuType",payload:[]})
          // dispatch({type:"actionsAllow",payload:{}})
          navigate("/")
          // // dispatch({type:"logout",payload:false})
         
          

        }}
          >Logout</MenuItem>
        </Menu>
      </Box>
      </Box>
      {/* <Drawer
        sx={{
          // mt:"1rem",
          width: 250,
          display:{xs:"block",md:"none"},
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 250,
            boxSizing: 'border-box',
            mt:"9vh",
            boxShadow:3
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      > */}
        <SideNav open={open} res={"res"} drawerWidth={250}/>
      {/* </Drawer> */}
   </AppBar>
  )
}

export default Nav