import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Paper,
  Button,
  Divider,
  InputLabel,
} from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import { Grid2 } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { api, companyInfo, roleInfo } from "../../API/config";
import { axiosPrivate } from "../../../axios/axios";
import { toast } from "react-toastify";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { commonCss } from "../../../StyleConfig/CommonCss";
import Loading from "../../../Component/Loading";

const RadiationCertificate = ({ handleCloseClick, certificateDetail }) => {
  const [showFields, setShowFields] = useState(false);
  const [showFieldsforother, setShowFieldsOthers] = useState(false);
  const login = useSelector((state) => state.auth.loginInfo);
  const [loading, setLoading] = useState(false);
  const [certificateInfo, setCertificateInfo] = useState({});
  const [approveInfo, setApproveInfo] = useState({
    engineerName: certificateInfo.engineerName,
    lpiName: certificateInfo.lpiName,
    certificateClosedName: certificateInfo.certificateClosedName,
  });

    const handleChange = (event) => {
        const isChecked = event.target.checked;
        setCertificateInfo((prevState) => ({
            ...prevState,
            isXRayMachine: isChecked, 
            otherEquipment:false
        }));
        setShowFields(isChecked); 
        setShowFieldsOthers(false); 
    };

    const handleChangeOther = (event) => {
        const isChecked = event.target.checked;
        setCertificateInfo((prevState) => ({
            ...prevState,
            isXRayMachine:false,
            otherEquipment: isChecked, 
        }));
        setShowFields(false); 
        setShowFieldsOthers(isChecked); 
    };
  useEffect(() => {
    if (certificateDetail && certificateDetail.id) {
      console.log("certificateDetail.id:", certificateDetail.id);
      getData();
    } else {
      console.log("certificateDetail is not available or doesn't have an id.");
    }
  }, [certificateDetail]);

    const getData = async () => {
        setLoading(true);
        const getDataLink = api.radiation.getById;
        await axiosPrivate
            .get(`${getDataLink}/${certificateDetail.id}`)
            .then((res) => {
                console.log("responce api", res.data.data);
                setCertificateInfo(res.data.data);
                setLoading(false);
            })
            .catch((e) => {
                setCertificateInfo({});
                setLoading(false);
                console.log(e);
            });
    };
  const initialValue = {
    id: certificateInfo.id,
    formId: certificateInfo.formId,
    radioactiveMaterialSpecified: certificateInfo.radioactiveMaterialSpecified,
    activityOfMaterial: certificateInfo.activityOfMaterial,
    containedWithin: certificateInfo.containedWithin,
    isXRayMachine: certificateInfo.isXRayMachine,
    otherEquipment: certificateInfo.otherEquipment,
    additionalInformation: certificateInfo.additionalInformation,
    measuresToPrevent: certificateInfo.measuresToPrevent,
    additionalControlsRequired: certificateInfo.additionalControlsRequired,
      radiationEquipmentFromDate: certificateInfo.radiationEquipmentFromDate,
      radiationEquipmentToDate: certificateInfo.radiationEquipmentToDate,
    engineerId: certificateInfo.engineerId,
    isEngineerApproved: certificateInfo.isEngineerApproved,
    engineerApprovedDate: certificateInfo.engineerApprovedDate,
    engineerRemarks: certificateInfo.engineerRemarks,
    isCertificateClosed: certificateInfo.isCertificateClosed,
    certificateClosedBy: certificateInfo.certificateClosedBy,
    certificateClosedAt: certificateInfo.certificateClosedAt,
    certificateClosedReason: certificateInfo.certificateClosedReason,
    lpiId: certificateInfo.lpiId,
    isLPIApproved: certificateInfo.isLPIApproved,
    lpiRemarks: certificateInfo.lpiRemarks,
    lpiApprovedDate: certificateInfo.lpiApprovedDate,
    certificateValidFrom: certificateInfo.certificateValidFrom,
      certificateValidTo: certificateInfo.certificateValidTo,
      // have to do in backend
      manufacturer: certificateInfo.manufacturer,
      voltage: certificateInfo.voltage,
      currentValue: certificateInfo.currentValue,
      otherEquipmentText: certificateInfo.otherEquipmentText
  };
    const onSubmit = async (value) => {
        const payload = {
            ...value, // form values if any
            isXRayMachine: certificateInfo.isXRayMachine, // ensure the updated checkbox value is included
            otherEquipment: certificateInfo.otherEquipment, // include the updated otherEquipment value
        };

        console.log(payload);

        const editLink = api.radiation.edit;
        await axiosPrivate
            .put(`${editLink}/${certificateDetail.id}`, payload)
            .then((res) => {
                console.log(res);
                getData(); // refresh the data after successful update
                toast.success("Successfully Updated");
            })
            .catch((e) => {
                console.log(e);
            });
    };


  return (
    <>
      {!loading ? (
        <Formik initialValues={initialValue} onSubmit={onSubmit}>
          {({ setFieldValue, values }) => (
            <Form>
              <Grid2 container size={12} spacing={"1rem"}>
                <Grid2 container size={12} alignItems={"center"}>
                  <Grid2 container size={4}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        display: "flex",
                        alignItems: "cente",
                        p: 1,
                        cursor: "pointer",
                      }}
                      onClick={handleCloseClick}
                    >
                      <ArrowBack color={commonCss.green} />
                    </Box>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"center"}>
                    <Typography variant="h5" fontWeight="bold" color="white">
                      Radiation Certificate
                    </Typography>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"flex-end"}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        p: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Permit To Work No.{" "}
                        <span style={{ color: "#e53e3e" }}>2701</span>
                      </Typography>
                    </Box>
                  </Grid2>
                  <Divider
                    sx={{
                      borderColor: commonCss.red,
                      borderWidth: 1,
                      width: "100%",
                    }}
                  />
                </Grid2>
                <Grid2 container size={12} alignItems={"center"}>
                  <Typography color="white" variant="body2">
                    Valid only for routine activities involving ionise radiation
                    source (To be completed only by authorised persons)
                  </Typography>
                </Grid2>
                <Grid2 container size={12} alignItems={"center"}>
                  <Typography color="white" variant="body2">
                    (To be completed only by authorised persons)
                  </Typography>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container spacing={2} mb={2}>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Radioactive material being used, specify
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="radioactiveMaterialSpecified"
                          value={values.radioactiveMaterialSpecified}
                          onChange={(e) =>
                            setFieldValue(
                              "radioactiveMaterialSpecified",
                              e.target.value
                            )
                          }
                          size="small"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Activity of material
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="activityOfMaterial"
                          value={values.activityOfMaterial}
                          onChange={(e) =>
                            setFieldValue("activityOfMaterial", e.target.value)
                          }
                          size="small"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Contained within
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="containedWithin"
                          value={values.containedWithin}
                          onChange={(e) =>
                            setFieldValue("containedWithin", e.target.value)
                          }
                          size="small"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                    </Grid2>
              
                    <Grid2 container spacing={2} mb={2}>
                        <Grid2 size={{ xs: 12, md: 3 }}>
                            <Grid2 item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={certificateInfo.isXRayMachine || false} // ensure it doesn't throw error when undefined
                                            onChange={handleChange}
                                            sx={{
                                                color: commonCss.green,
                                                "&.Mui-checked": {
                                                    color: commonCss.green,
                                                },
                                            }}
                                        />
                                    }
                                    label="X-ray machine"
                                />
                            </Grid2>
                            <Grid2 item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={certificateInfo.otherEquipment || false} // ensure it doesn't throw error when undefined
                                            onChange={handleChangeOther}
                                            sx={{
                                                color: commonCss.green,
                                                "&.Mui-checked": {
                                                    color: commonCss.green,
                                                },
                                            }}
                                        />
                                    }
                                    label="Other equipment"
                                />
                            </Grid2>
                        </Grid2>
                            

                      {certificateInfo.isXRayMachine && (
                        <>
                          <Grid2 size={{ xs: 12, md: 5 }}>
                            <InputLabel sx={{ pl: commonCss.labelPadding }}>
                              <Typography
                                variant="body2"
                                fontWeight={commonCss.labelFontWeight}
                                color={commonCss.labelInputColor}
                              >
                                Manufacturer/Model
                              </Typography>
                            </InputLabel>
                            <TextField
                              fullWidth
                              variant="outlined"
                            size="small"
                            name="manufacturer"
                            value={values.manufacturer}
                            onChange={(e) =>
                                setFieldValue(
                                    "manufacturer",
                                    e.target.value
                                )
                            }
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                  borderRadius: commonCss.inputBorderRadius,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                            />
                          </Grid2>
                          <Grid2 size={{ xs: 12, md: 2 }}>
                            <InputLabel sx={{ pl: commonCss.labelPadding }}>
                              <Typography
                                variant="body2"
                                fontWeight={commonCss.labelFontWeight}
                                color={commonCss.labelInputColor}
                              >
                                Voltage
                              </Typography>
                            </InputLabel>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder="KV"
                            size="small"
                            name="voltage"
                            value={values.voltage}
                            onChange={(e) =>
                                setFieldValue(
                                    "voltage",
                                    e.target.value
                                )
                            }
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                  borderRadius: commonCss.inputBorderRadius,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              inputProps={{
                                style: { textAlign: "right" },
                              }}
                            />
                          </Grid2>
                          <Grid2 size={{ xs: 12, md: 2 }}>
                            <InputLabel sx={{ pl: commonCss.labelPadding }}>
                              <Typography
                                variant="body2"
                                fontWeight={commonCss.labelFontWeight}
                                color={commonCss.labelInputColor}
                              >
                                Current
                              </Typography>
                            </InputLabel>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder="mA"
                            size="small"
                            name="currentValue"
                            value={values.currentValue}
                            onChange={(e) =>
                                setFieldValue(
                                    "currentValue",
                                    e.target.value
                                )
                            }
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                  borderRadius: commonCss.inputBorderRadius,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              inputProps={{
                                style: { textAlign: "right" },
                              }}
                            />
                          </Grid2>
                        </>
                      )}

                      {certificateInfo.otherEquipment && (
                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <Grid2 item xs={6} md={6}>
                            <InputLabel>
                              <Typography
                                variant="body2"
                                fontWeight="bold"
                                color="black"
                              ></Typography>
                            </InputLabel>
                            <TextField
                              fullWidth
                              variant="outlined"
                              multiline
                            size="small"
                            name="otherEquipmentText"
                            value={values.otherEquipmentText}
                            onChange={(e) =>
                                setFieldValue(
                                    "otherEquipmentText",
                                    e.target.value
                                )
                            }
                              rows={2}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                  borderRadius: commonCss.inputBorderRadius,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                            />
                          </Grid2>
                        </Grid2>
                      )}
                    </Grid2>
                    <Grid2 container spacing={2} mb={2}>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            Additional information about operation of equipment
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="additionalInformation"
                          value={values.additionalInformation}
                          onChange={(e) =>
                            setFieldValue(
                              "additionalInformation",
                              e.target.value
                            )
                          }
                          multiline
                          size="small"
                          rows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            Measures to prevent and mitigate exposure
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="measuresToPrevent"
                          value={values.measuresToPrevent}
                          onChange={(e) =>
                            setFieldValue("measuresToPrevent", e.target.value)
                          }
                          multiline
                          size="small"
                          rows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2} mb={2} alignItems={"flex-end"}>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            Additional controls required
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={values.additionalControlsRequired}
                          name="additionalControlsRequired"
                          onChange={(e) =>
                            setFieldValue(
                              "additionalControlsRequired",
                              e.target.value
                            )
                          }
                          multiline
                          size="small"
                          rows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 container spacing={2} size={{ xs: 12, md: 6 }}>
                        <Grid2 size={12}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Radiation equipment will be used between
                            </Typography>
                          </InputLabel>
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <InputLabel sx={{ pl: commonCss.labelPadding }}>
                                <Typography
                                    variant="body2"
                                    fontWeight={commonCss.labelFontWeight}
                                    color={commonCss.labelInputColor}
                                >
                                    From
                                </Typography>
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoItem>
                                                          <DateTimePicker
                                                              ampm={false}
                                                              format="DD/MM/YYYY HH:mm"
                                                              value={
                                                                  values.radiationEquipmentFromDate !== null
                                                                      ? dayjs(values.radiationEquipmentFromDate)
                                                                      : null
                                                              }
                                                              viewRenderers={{
                                                                  hours: renderTimeViewClock,
                                                                  minutes: renderTimeViewClock,
                                                                  seconds: renderTimeViewClock,
                                                              }}
                                                              slotProps={{
                                                                  textField: {
                                                                      size: "small",
                                                                      variant: "outlined",
                                                                  },
                                                              }}
                                                              onChange={(newValue) => {
                                                                  console.log(newValue);
                                                                  if (newValue !== null) {
                                                                      if (`${newValue.$d}` !== "Invalid Date") {
                                                                          const dateFrom = moment(newValue.$d).utc()
                                                                          setFieldValue(
                                                                              "radiationEquipmentFromDate",
                                                                              dateFrom
                                                                          );

                                                                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                                                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                                                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                                                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                                                          // setFieldValue("installationDate", dateFormat);
                                                                      }
                                                                  }
                                                              }}
                                                              sx={{
                                                                  "& .MuiOutlinedInput-root": {
                                                                      borderRadius: commonCss.inputBorderRadius,
                                                                      backgroundColor:
                                                                          commonCss.inputBackgroundColor,
                                                                  },
                                                                  "& .MuiOutlinedInput-notchedOutline": {
                                                                      borderColor: commonCss.inputBorderColor,
                                                                  },
                                                              }}
                                                              fullWidth
                                                          />
                                </DemoItem>
                            </LocalizationProvider>
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Until
                            </Typography>
                          </InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoItem>
                              <DateTimePicker
                                ampm={false}
                                format="DD/MM/YYYY HH:mm"
                                value={
                                    certificateInfo.radiationEquipmentToDate
                                        ? dayjs(certificateInfo.radiationEquipmentToDate)
                                        : null
                                }
                                onChange={(newValue) => {
                                    console.log(newValue);
                                    if (newValue !== null) {
                                        if (`${newValue.$d}` !== "Invalid Date") {
                                            const dateFrom = moment(newValue.$d).utc();
                                            setFieldValue("radiationEquipmentToDate", dateFrom);
                                        }
                                    }
                                }}
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    variant: "outlined",
                                  },
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor:
                                      commonCss.inputBackgroundColor,
                                    borderRadius: commonCss.inputBorderRadius,
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: commonCss.inputBorderColor,
                                  },
                                }}
                                fullWidth
                              />
                            </DemoItem>
                          </LocalizationProvider>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          Engineer
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          I certify that I have reviewed the proposed work and I
                          am satisfied that it is adequately described on the
                          permit to work and that the precautions detailed are
                          adequate and clearly defined
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.engineerName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.isEngineerApproved
                                  ? dayjs(values.engineerApprovedDate)
                                  : null
                              }
                              readOnly
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                          variant="contained"
                          disabled={values.isEngineerApproved}
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                          }}
                          onClick={() => {
                            setFieldValue("isEngineerApproved", true);
                            setFieldValue("engineerId", login.userId);
                            setFieldValue(
                              "engineerApprovedDate",
                              moment().format("YYYY-MM-DDTHH:mm:ss")
                            );
                            setApproveInfo({
                              ...approveInfo,
                              engineerName:
                                login.firstName + " " + login.lastName,
                            });
                          }}
                        >
                          Approve
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          LPI
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          Hot Work is described in the permit to work is allowed
                          between:
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.lpiName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              readOnly
                              value={
                                values.isLPIApproved
                                  ? dayjs(values.lpiApprovedDate)
                                  : null
                              }
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            From
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).format(
                                      "YYYY-MM-DDTHH:mm:ss"
                                    );
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Until
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).format(
                                      "YYYY-MM-DDTHH:mm:ss"
                                    );
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                          }}
                          onClick={() => {
                            setFieldValue("isLPIApproved", true);
                            setFieldValue("lpiId", login.userId);
                            setFieldValue(
                              "lpiApprovedDate",
                              moment().format("YYYY-MM-DDTHH:mm:ss")
                            );
                            setApproveInfo({
                              ...approveInfo,
                              lpiName: login.firstName + " " + login.lastName,
                            });
                          }}
                        >
                          Approve
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 item xs={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          Hot Work certificate is certificate and permit to work
                          are withdrawn:
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          value={
                            values.isCertificateClosed !== null
                              ? approveInfo.certificateClosedName
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly
                              value={
                                values.isCertificateClosed
                                  ? dayjs(values.certificateClosedAt)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Reason
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="certificateClosedReason"
                          value={values.certificateClosedReason}
                          onChange={handleChange}
                          multiline
                          minRows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                          variant="contained"
                          disabled={values.isCertificateClosed}
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                          }}
                          onClick={() => {
                            setFieldValue("isCertificateClosed", true);
                            setFieldValue("certificateClosedBy", login.userId);
                            setFieldValue(
                              "certificateClosedAt",
                              moment().format("YYYY-MM-DDTHH:mm:ss")
                            );
                            setApproveInfo({
                              ...approveInfo,
                              certificateClosedName:
                                login.firstName + " " + login.lastName,
                            });
                          }}
                        >
                          Close
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12} justifyContent={"flex-end"}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: commonCss.green,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                    }}
                  >
                    Submit
                  </Button>
                </Grid2>
              </Grid2>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default RadiationCertificate;
