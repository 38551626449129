import React, { Fragment, useState } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import {
  TableRow,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Box,
  TextField,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Icon,
  TableContainer,
  Checkbox,
} from "@mui/material";
import { Filter, DefaultColumnFilter } from "./filters";

import { ArrowBackIos, ArrowForwardIos, KeyboardArrowLeft, KeyboardArrowRight, SkipNext, SkipPrevious } from "@mui/icons-material";
import { commonCss } from "../StyleConfig/CommonCss";


const NormalTable = ({ columns, data, renderRowSubComponent,filt,handleCheckAll,checkBol,hideColumns,paginationProps,hideHeader }) => {
  const [pageIndex, setPageIndex] = useState(0);
 
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
   
    state: {  pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: pageIndex, pageSize: 10, hiddenColumns: hideColumns!==undefined?hideColumns:[],},
   
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  
  const handlePageChange = (newPage) => {
    setPageIndex(newPage);
    gotoPage(newPage);
  };



  return (
    <Fragment>
      <TableContainer>
        
      <Table {...getTableProps()}>
        
        <TableHead sx={{display:hideHeader?"none":"default"}}>
          {headerGroups.map((headerGroup) => {
          
            return(
                       <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column,i) => (
                  
                    <Fragment key={i}>
                    <TableCell
                        {...column.getHeaderProps()}
                        sx={{backgroundColor:"transparent",padding:"1rem 0.4rem",fontSize:"13px",fontWeight:700,
                        whiteSpace:"nowrap",
                        display:column.enableHiding?"none":"tableCell"
                      }}
                      >
                       
                        {column.render("Header")==="checkBox"?
                        <Checkbox checked={checkBol} onClick={handleCheckAll}/>:
                         <div {...column.getSortByToggleProps()}>
                         {column.render("Header")}
                         {generateSortingIndicator(column)}
                       </div>
                       }
                         
                        
                        {filt?<Filter column={column} />:null}  
                      
                      </TableCell>
                      
                      </Fragment>
                   )
              
          
              )}
            </TableRow>
         )
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            // console.log(row)
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
              <TableRow >
                    
                {row.cells.map((cell,i) => (
                    <Fragment key={i}>
                   <TableCell
                      {...cell.getCellProps()}
                      sx={{ fontSize:"14px",fontWeight:400, color:"black", border:"none",borderBottom:"1px solid rgba(224, 224, 224, 1)",padding:"0.5rem",display:cell.column.enableHiding?"none":"tableCell"}}
                    >
                 
                      {cell.render("Cell")}
                    </TableCell>
                   
                    </Fragment>
                  )
                )}
              </TableRow>
               {row.isExpanded ? (
                <TableRow>
                  <TableCell sx={{padding:0}} colSpan={row.cells.length} >
                    
                    {renderRowSubComponent({ row })}
                  </TableCell>
                </TableRow>
              ) : null}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    
      <Box
            sx={{
              backgroundColor: commonCss.green,
              display:paginationProps?"default":"none",
              
            }}
          >
            
            <Box
              sx={{
                display: "flex",
                gap: { xs: "5px", md: "2rem" },
                justifyContent: "flex-end",
                alignItems: "center",
                p: "0.4rem",
                whiteSpace: "nowrap",
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <Typography sx={{ display: { xs: "none", md: "block" } }}>
                  Rows:
                </Typography>
                <Select
                  variant="standard"
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <MenuItem key={pageSize} value={pageSize}>
                      {pageSize}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Typography>
                  {pageIndex + 1 + " of " + pageOptions.length}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <IconButton
                  onClick={() => handlePageChange(0)}
                  disabled={!canPreviousPage}
                >
                  <SkipPrevious
                    sx={{
                      color: !canPreviousPage ? "gray" : "blue",
                      fontSize: "1.5rem",
                    }}
                  />
                </IconButton>
                <IconButton onClick={()=>handlePageChange(pageIndex - 1)} disabled={!canPreviousPage}>
                  <KeyboardArrowLeft
                    sx={{
                      color: !canPreviousPage ? "gray" : "blue",
                      fontSize: "1.5rem",
                    }}
                  />
                </IconButton>

                <IconButton onClick={()=>handlePageChange(pageIndex + 1)} disabled={!canNextPage}>
                  <KeyboardArrowRight
                    sx={{
                      color: !canNextPage ? "gray" : "blue",
                      fontSize: "1.5rem",
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => handlePageChange(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <SkipNext
                    sx={{
                      color: !canNextPage ? "gray" : "blue",
                      fontSize: "1.5rem",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
     
      </TableContainer>
    </Fragment>
  );
};

export default NormalTable;
