import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  Divider,
  Grid2,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";

import { axiosPrivate } from "../../../axios/axios";
import { api } from "../../API/config";
import { commonCss } from "../../../StyleConfig/CommonCss";

const SiteModal = ({ mode, handleCloseClick, editData }) => {
  const [loading, setLoading] = useState(false);
  const [terminalAuto, setTerminalAuto] = useState("");
  const [terminal, setTerminal] = useState([]);

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const getTerminal = api.terminal.getAll;
    await axiosPrivate
      .get(`${getTerminal}`)
      .then((res) => {
        setTerminal(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const initialValue = {
    name: mode === "Edit" ? editData.name : "",
    description: mode === "Edit" ? editData.description : "",
    terminalId: mode === "Edit" ? editData.terminalId : null,
  };

  const onSubmit = async (value) => {
    console.log(value);
    setLoading(true);

    const createLink = api.site.add;
    const updateLink = api.site.edit;
    if (mode === "Create") {
      await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create Site");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };

      await axiosPrivate
        .put(`${updateLink}/${editData.id}`, updateAC)
        .then((res) => {
          console.log(res);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "update Site");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: commonCss.titleColor,
            fontSize: commonCss.titleFontSize,
            fontWeight: commonCss.titleFontWeight,
          }}
        >
          {mode === "Edit" ? editData.name : "New Site"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid2 container spacing={"1rem"}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Name
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type="text"
                  name="name"
                  value={values.name}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Terminal
                  </Typography>
                </InputLabel>
                <Autocomplete
                  name="terminalId"
                  inputValue={terminalAuto}
                  filterOptions={filterOptions}
                  loading={terminal.length === 0 && loading}
                  options={terminal}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.name || option}
                  onChange={(event, value) => {
                    if (value) {
                      setFieldValue("terminalId",value.id)
                      setTerminalAuto(value.name);
                    } else {
                      setFieldValue("terminalId",null)
                      setTerminalAuto("");
                    }
                  }}
                  onInputChange={(e, v) => {
                    setTerminalAuto(v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="terminalId"
                      size="small"
                      // variant="standard"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: commonCss.inputBackgroundColor,
                          borderRadius: commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                      // slotProps={{
                      //   input: {
                      //     ...params.inputProps,
                      //     type:"select",
                      //     endAdornment: (
                      //       <React.Fragment>

                      //         {terminal.length === 0 ? (
                      //           <CircularProgress color="inherit" size={20}  />
                      //         ) : null}

                      //         {params.InputProps.endAdornment}
                      //       </React.Fragment>
                      //     ),
                      //   },
                      // }}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={12}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Description
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type="text"
                  name="description"
                  value={values.description}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>

              <Grid2 container size={12} justifyContent={"flex-end"} gap="1rem">
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: commonCss.green,
                    borderRadius: commonCss.buttonBorderRadius,
                    textTransform: "none",
                    padding: "0.6rem 1rem",
                    color: commonCss.buttonTextColor,
                    width: "10vw",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    backgroundColor: commonCss.green,
                    borderRadius: commonCss.buttonBorderRadius,
                    textTransform: "none",
                    padding: "0.6rem 1rem",
                    color: commonCss.buttonTextColor,
                    width: "10vw",
                  }}
                >
                  Submit
                </Button>
              </Grid2>
            </Grid2>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SiteModal;
