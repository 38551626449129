import axios, { AxiosRequestConfig } from "axios";
import {store} from "../Redux/store"
import {toast} from "react-toastify"


// export const baseURL="https://eptw-api.solverthoughts.com/api"
export const baseURL="https://opc.orienseam.com/api"




export const axiosPrivate = axios.create({
  baseURL:baseURL,
});




  



axiosPrivate.interceptors.request.use(
  (config)=> {
    const storeData = store.getState();
    const  tokenData=storeData.auth.loginInfo.jwtToken
    if(tokenData!==undefined){
      config.headers = config.headers || {};
      config.headers["Authorization"] ="Bearer " + tokenData ;
    }
    return config;
  }
);

axiosPrivate.interceptors.response.use(async function(config){
   
  return config;
},(error) => {
  // console.log(error.response, "errrr");
  return new Promise( (resolve) => {

    const originalRequest = error.config;
    
      originalRequest._retry = true;

      // body: formBody
    
      
        
        
     
    
    // else{
    //   throw error;
    // }

    if (error.response) {
      throw error;
    }

    return Promise.reject(error);
  });
})




