import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loading = () => {
  return (
    <Box sx={{display:"flex",width:"100%",height:"40vh",alignItems:"center",justifyContent:"center"}}>
        <CircularProgress disableShrink sx={{color:"white"}} />
    </Box>
  )
}

export default Loading