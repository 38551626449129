import { Button, Divider, Grid2, Paper, Typography,TextField,Select,InputLabel,Box, IconButton, Dialog, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { commonCss } from '../../../StyleConfig/CommonCss'
import { Add, ArrowBack, DeleteOutline, FileUploadOutlined } from '@mui/icons-material'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {  EditOutlined } from '@mui/icons-material'
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { useSelector } from 'react-redux';
import NormalTable from '../../../Component/NormalTable';
import LabourSelect from '../LabourSelect';
import PSImageRiskAssessment from "../../../Images/PSImageRiskAssessment.png"
import NormalTableWithRadius from '../../../Component/NormalTableWithRadius'
import Loading from '../../../Component/Loading';
import { Form, Formik } from 'formik';
import { api } from '../../API/config';
import { useSearchParams } from 'react-router-dom';
import { axiosPrivate } from '../../../axios/axios';
import moment from 'moment';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const RiskAssessment = ({handleCloseClick}) => {
    const [searchParams,setSearchParams]=useSearchParams()
    
    const [loading,setLoading]=useState(false)
    const [data,setData]=useState({})
    const [editData,setEditData]=useState(false)
    const [overAll,setOverAll]=useState([])

    const [labourList,setLabourList]=useState([])
    const [labour,setLabour]=useState("")
    const [editLabour,setEditLabour]=useState({bol:false,i:null})
    const [hazardData,setHazardData]=useState([])
    const [getHazard,setHazard]=useState({
      hazardsIdentification:"",
      riskAssessmentText:null,
      riskControl:"",
      additionalControl:"",
      thoseAtRisk:"",
      
  })
  const [editHazard,setEditHazard]=useState({bol:false,i:null})

  useEffect(()=>{
    getData()
  },[])

  const getData=async()=>{
    setLoading(true)
    const getLink=api.riskAssessment.getByFormId
    await axiosPrivate.get(`${getLink}?formId=${searchParams.get("id")}`).then(async(res)=>{
      console.log(res)
      if(res.data.data.length!==0){
        setData(res.data.data[0])
        setEditData(true)
       await getHazardDataList(res.data.data[0])
      }else{
        setData({})
        setEditData(false)
      }
      
     
    }).catch(e=>{
      setData({})
     
    })

    const getOverAllLink=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getOverAllLink}?keyName=overallAssessment`).then(res=>{
      setOverAll(res.data.data)
    }).catch(e=>{
      console.log(e)
      setOverAll([])
    })
    setLoading(false)
    }
    const getHazardDataList=async(dataId)=>{
      const getByRiskIdLink=api.riskAssessment.getByRiskId
      await axiosPrivate.get(`${getByRiskIdLink}?riskAssessmentId=${dataId.id}`).then(res=>{
        setHazardData(res.data.data)
      }).catch(e=>{
        console.log(e)
      }) 

    

  }

  const initialValue={
formId: searchParams.get("id"),
  riskAssessmentOf: editData?data.riskAssessmentOf:"",
  assessmentDate: editData?data.assessmentDate:moment().utc(),
  activityDescription: editData?data.activityDescription:"",
  equipmentDescription:editData?data.equipmentDescription:"",
  overallAssessment: editData?data.overallAssessment:null,
  otherComments:editData?data.otherComments:""
  }
  const onSubmit=async(value)=>{
    console.log(value)
  if(editData){
    const editLink=api.riskAssessment.edit
    await axiosPrivate.put(`${editLink}/${data.id}`,{id:data.id,...value}).then(res=>{
     toast.success("Successfully Updated")
     getData()
    }).catch(e=>{
      console.log(e)
      toast.error("Not Updated")
    })
  }else{
    const createLink=api.riskAssessment.add
    await axiosPrivate.post(`${createLink}`,value).then(res=>{
      toast.success("Successfully Updated")
      getData()
    }).catch(e=>{
      toast.error("Not Updated")
      console.log(e)
    })
  }
  }
    
    const labourColumns= [
       
        {
          Header:"Labour Name",
          accessor: "name",
          // disableFilters: true,
        },
        {
          accessor:"id",
          Cell: ({ cell }) => {
            return (
              <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
              
                <IconButton onClick={()=>{
                  setEditLabour({bol:true,i:cell.row.id})
                  setLabour(cell.row.original.name)
                }} >
                  <EditOutlined sx={{color:commonCss.red}}/>
                </IconButton>
               
               
              </Box>
            );
          },
        }
        
      ]

      const listOfInstruction=[
        "Electricity", 
        "Noise", 
        "Lasers" ,
        "Radiation", 
        "Vacuum ",
        "Legionella", 
        "Weil's Disease", 
        "Other Biological (Specify)", 
        "Psychological Hazards", 
        "High Pressure", 
        "Explosion",
        "Fire Hazard", 
        "Gas Vapor Release", 
        "Toxic Chemicals" ,
        "Flammable Substances ",
         "Hazard to eyes", 
         "Hand Tools ",
         "Dangerous Machinery ",
         "Extreme of Temperature", 
         "Confined Spaces", 
         "Working environment ",
         "Manual Handling ",
         "Gravity (Height or above excavations)", 
         "Moving Vehicles" ,
         "Cleaning Devices", 
         "Slips, Trip, and Falls" ,
         "Environmental Effects", 
         "Other",
      ]

      const handleHazard=async()=>{
        if(editHazard.bol){
          const putData={id:editHazard.i.id,...getHazard}
         const editLink=api.riskAssessment.hazardEdit
         await axiosPrivate.put(`${editLink}/${editHazard.i.id}`,putData).then(res=>{
         
          getHazardDataList(data)
          setHazard({
            hazardsIdentification:"",
            riskAssessmentText:"",
            riskControl:"",
            additionalControl:"",
            thoseAtRisk:"",
        })
        toast.success("Successfull Sheet Updated")
         }).catch(e=>{
          toast.error("Not Updated")
          console.log(e)
         })
         
        }else{
            const postData={
              ...getHazard,
              riskAssessmentId:data.id
            }
            console.log(postData)
           const createLink=api.riskAssessment.hazardAdd
           await axiosPrivate.post(`${createLink}`,postData).then(res=>{
            console.log(res)
          
            getHazardDataList(data)
            setHazard({
              hazardsIdentification:"",
              riskAssessmentText:"",
              riskControl:"",
              additionalControl:"",
              thoseAtRisk:"",
          })
           }).catch(e=>{
            console.log(e)
           })
        
        }
        
    }

    const hazardColumns=[
      {
        Header:"Hazards Identification",
        accessor:"hazardsIdentification"
      },
      
      {
          Header:"Risk Assessment (Impact)",
          accessor:"riskAssessmentText"
        },
        {
          Header:"Risk Control",
          accessor:"riskControl"
        },
        {
            Header:"Additional Control",
            accessor:"additionalControl"
          },
          {
            Header:"Those at Risk",
            accessor:"thoseAtRisk"
          },
      {
        accessor:"id",
        Cell: ({ cell }) => {
          return (
            <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"center",width:"100%",alignItems:'center' }}>
            
              <IconButton 
              onClick={()=>{
                setEditHazard({bol:true,i:cell.row.original})
                setHazard(cell.row.original)
              }}
              >
                <EditOutlined sx={{color:commonCss.red}}/>
                
              </IconButton>
             
            </Box>
          );
        },
      }
    ]

    const handleAccessor=()=>{
       if(editLabour.bol){
        let updateLabour=[...labourList] 
        updateLabour[editLabour.i]={name:labour}
        setLabourList(updateLabour)
        setEditLabour({bol:false,i:null})
        setLabour("")
       }else{
        setLabourList([...labourList,{name:labour}])
        setLabour("")
       }
    }

     
    



  return (
    <>
    {!loading?
    <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
    <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 container size={12} alignItems={"center"}>
         <Grid2 container size={4} >
        <Box
          sx={{
            backgroundColor: commonCss.shadeGreen,
            display:"flex",
            alignItems:"cente",
            p:1,
            cursor:"pointer"
          }}
          onClick={handleCloseClick}
        >
          <ArrowBack color={commonCss.green} />
        </Box>
        </Grid2>
        <Grid2 container size={4} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
          Risk Assessment
          </Typography>
        </Grid2>
        <Grid2 container size={4} justifyContent={"flex-end"}>
        <Box
          sx={{
            backgroundColor: commonCss.shadeGreen,
            p: 1,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            Permit To Work No. <span style={{ color: "#e53e3e" }}>2701</span>
          </Typography>
        </Box>
        </Grid2>
        <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%' }} />
        </Grid2>
       
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"}>
        <Grid2 size={{xs:12,md:6}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Risk Assessment of
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="riskAssessmentOf"
              value={values.riskAssessmentOf}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={values.assessmentDate!==null?dayjs(values.assessmentDate):null}
                  onChange={(newValue) => {
                    console.log(newValue);
                    if (newValue !== null) {
                      if (`${newValue.$d}` !== "Invalid Date") {
                        const dateFrom = moment(newValue.$d).utc()
                        setFieldValue("assessmentDate",dateFrom)
                        
                        // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                        // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                        // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                        // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                        // setFieldValue("installationDate", dateFormat);
                      }
                    }
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
          <Grid2 container size={12} alignItems={"center"} spacing={1}>
          <Grid2  size={7}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Staff List
              </Typography>
              </InputLabel>
              <Box sx={{display:"flex",gap:"1rem",alignItems:'center'}}>
              <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={labour}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={(e)=>{setLabour(e.target.value)}}
            />
              <IconButton sx={{border:`3px solid ${commonCss.red}`,p:0}} onClick={handleAccessor} >
            <Add sx={{color:commonCss.red}}/>
         </IconButton>
              </Box>
          </Grid2>
         
          <Grid2 size={7} sx={{display:labourList.length!==0?"default":"none"}}>
            <Paper elevation={4} >
               {/* <Collapse in={labour} > */}
                <NormalTable data={labourList} columns={labourColumns} />
               {/* </Collapse > */}
               </Paper>
          </Grid2>
        </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Description of activity
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              multiline
              name="activityDescription"
              value={values.activityDescription}
              onChange={handleChange}
              rows={2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Description of equipment
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="equipmentDescription"
              value={values.equipmentDescription}
              onChange={handleChange}
              multiline
              rows={2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red} >
          
          HAZARD ANALYSIS WORKSHEET
          </Typography>
          </Grid2 >
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Hazards Identification
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getHazard.hazardsIdentification}
              onChange={(e)=>{
                setHazard({
                  ...getHazard,hazardsIdentification:e.target.value
                })
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Risk Assessment (Impact)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getHazard.riskAssessmentText}
              onChange={(e)=>{
                setHazard({
                  ...getHazard,riskAssessmentText:e.target.value
                })
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Risk Control (Existing Mitigation)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getHazard.riskControl}
              onChange={(e)=>{
                setHazard({
                  ...getHazard,riskControl:e.target.value
                })
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Additional Control (If any)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getHazard.additionalControl}
              onChange={(e)=>{
                setHazard({
                  ...getHazard,additionalControl:e.target.value
                })
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
           
           
           
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Those at Risk
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getHazard.thoseAtRisk}
              onChange={(e)=>{
                setHazard({
                  ...getHazard,thoseAtRisk:e.target.value
                })
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 container size={{xs:12}} justifyContent={"center"}>
          
          <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={handleHazard}
            
          >
           Add Worksheet
          </Button>
          </Grid2>
          <Grid2 size={{xs:12}} sx={{display:hazardData.length===0?"none":"default"}}>
            <Paper sx={{background:"transparent"}}>
            <NormalTableWithRadius data={hazardData} columns={hazardColumns}/>
            </Paper>
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              Overall Assessment of Risk (using Risk Assessment Matrix): Action needed: (inc responsible party and closeout target)
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="overallAssessment"
                value={values.overallAssessment}
                onChange={handleChange}
                sx={{
                  borderRadius: commonCss.inputBorderRadius,
                  backgroundColor: commonCss.inputBackgroundColor,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
                fullWidth
              >
                {overAll.map((all,i)=>(
                <MenuItem value={all.id}>{all.name}</MenuItem>
                ))}
                
              </Select>
          </Grid2>
          <Grid2 container size={{xs:12,md:6}} alignItems={"flex-end"}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Comments (if any)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="otherComments"
              value={values.otherComments}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:'100%',textTransform:"uppercase"}}
            >
         
        <Grid2 container size={12} spacing={"0.7rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
         
          NON EXHAUSTIVE LIST OF HAZARD CATEGORIES
          </Typography>
          </Grid2 >
        <Grid2 container size={12}>
            {listOfInstruction.map((listOf,i)=>(
                <Grid2 size={4} key={i}>
                    <Typography  variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                        {listOf}
                    </Typography>
                </Grid2>
            ))}
        </Grid2>
        <Grid2 container size={12}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green} >
         
          WHEN CONDUCTING A RISK ASSESSMENT
          </Typography>
          </Grid2 >
          <Grid2 size={12} >
                    <Typography  variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                    The assessment should be carried out in good time prior to the relevant decision being made 
When a specific assessment is needed then such an assessment, rather than a generic assessment should be made 
The risk from each activity should be considered  
A team of people or employees with practical knowledge of the process/activity being assessed should be used as appropriate 
Consultants should be used where necessary 
All the hazards associated with a particular activity should be considered 
Hazards and risk controls should be linked 
The results of the assessment should be properly used.
                    </Typography>
                </Grid2>
        </Grid2>
        <Grid2 container size={12}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green} sx={{textTransform:"uppercase"}}>
          PS Pipeline to provide high resolution image
          </Typography>
          </Grid2 >
          <Grid2 size={12}>
            <img src={PSImageRiskAssessment} style={{width:"100%"}} alt="PS image"/>
          </Grid2>
          </Grid2>
          
        </Grid2>
        
            </Paper>
        </Grid2>
       
        <Grid2 container size={12} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            type='submit'
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"10vw"
            }}
            // onClick={()=>{setLabour(true)}}
          >
           Submit
          </Button>
          </Grid2>

          
    </Grid2>
    </Form>
        )}

        </Formik>
        :<Loading/>}
        </>
  )
}

export default RiskAssessment