import {
  Button,
  Divider,
  Grid2,
  Paper,
  Typography,
  TextField,
  Select,
  InputLabel,
  Box,
  IconButton,
} from "@mui/material";
import { commonCss } from "../../../StyleConfig/CommonCss";
import {
  Add,
  ArrowBack,
  EditOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import NormalTable from "../../../Component/NormalTable";
import { Form, Formik } from "formik";
import { api, companyInfo, roleInfo } from "../../API/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Loading from "../../../Component/Loading";
import { axiosPrivate } from "../../../axios/axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import moment from "moment";

const MethodStatement = ({ handleCloseClick }) => {
  const [workCompleteData, setWorkCompleteData] = useState([]);
  const [workComplete, setWorkComplete] = useState();
  const [editWC, setEditWC] = useState({ bol: false, i: null });
  const [loading, setLoading] = useState(false);
  const [workCompletedNotes, setWorkCompletedNotes] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [editData, setEditData] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    siteLocationDrawings: false,
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const getLink = api.methodstatement.getByFormId;

    await axiosPrivate
      .get(`${getLink}?formId=${searchParams.get("id")}`)
      .then(async (res) => {
        console.log(res);
        if (res.data.data.length !== 0) {
          setWorkCompleteData(
            res.data.data[0]?.workCompletedNotes !== null
              ? String(res.data.data[0].workCompletedNotes).split(",")
              : []
          );
          setData(res.data.data[0]);
          setEditData(true);
          console.log("responce api", res.data.data);
        }
      })
      .catch((e) => {
        console.error("Error fetching data: ", e);
        setData({});
      });
    setLoading(false);
    //    .finally(() => {
    //    setLoading(false);
    //});
  };

  const workColumns = [
    {
      accessor: "step",
    },
    {
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Box
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setEditWC({ bol: true, i: cell.row.id });
                setWorkComplete(cell.row.original.step);
              }}
            >
              <EditOutlined sx={{ color: commonCss.red }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const initialValues = {
    formId: searchParams.get("id"),
    company: "",
    project: "",
    datetime: null,
    durationOfWork: editData ? data.durationOfWork : "",
    siteLocationDrawings: editData ? data.siteLocationDrawings : "",
    restrictions: editData ? data.restrictions : "",
    localPermitRequired: editData ? data.localPermitRequired : "",
    otherWorkDetails: editData ? data.otherWorkDetails : "",
    projectWorkerDetails: editData ? data.projectWorkerDetails : "",
    inChargeDetails: editData ? data.inChargeDetails : "",
    responsibilitiesSpecified: editData ? data.responsibilitiesSpecified : "",
    localAuthoritiesContact: editData ? data.localAuthoritiesContact : "",
    otherPersonDetails: editData ? data.otherPersonDetails : "",
    plantEquipmentRequired: editData ? data.plantEquipmentRequired : "",
    workingTools: editData ? data.workingTools : "",
    sparkTools: editData ? data.sparkTools : "",
    othersTools: editData ? data.othersTools : "",
    certificatesRequired: editData ? data.certificatesRequired : "",
    trainingRequired: editData ? data.trainingRequired : "",
    proceduresCommunicatedWorkforce: editData
      ? data.proceduresCommunicatedWorkforce
      : "",
    proposedStartDate: editData ? data.proposedStartDate : moment().utc(),
    proposedEndDate: editData ? data.proposedEndDate : moment().utc(),
    workCompletedNotes: editData ? data.workCompletedNotes : "",
  };

  const handleWC = () => {
    if (!workComplete) return;

    let updatedWork = [...workCompleteData];

    if (editWC.bol) {
      updatedWork[editWC.i] = { step: workComplete };
      setEditWC({ bol: false, i: null });
    } else {
      updatedWork.push({ step: workComplete });
    }

    setWorkCompleteData(updatedWork);
    setWorkComplete("");

    const commaSeparatedSteps = updatedWork.map((item) => item.step).join(", ");
    setWorkCompletedNotes(commaSeparatedSteps);
  };

  const onSubmit = async (value) => {
    const updatedValues = {
      ...value,
      workCompletedNotes: workCompletedNotes.toString(),
    };
    console.log("OnSub", value);
    if (editData) {
      const editLink = api.methodstatement.edit;
      console.log("updated value", updatedValues);
      await axiosPrivate
        .put(`${editLink}/${data.id}`, { id: data.id, ...updatedValues })
        .then((res) => {
          toast.success("Successfully Updated");
          getData();
        })
        .catch((e) => {
          console.log(e);
          toast.error("Not Updated");
        });
    } else {
      const createLink = api.methodstatement.create;
      await axiosPrivate
        .post(`${createLink}`, updatedValues)
        .then((res) => {
          toast.success("Successfully Created");
          getData();
        })
        .catch((e) => {
          toast.error("Not Created");
          console.log(e);
        });
    }
  };

  const handleFileUpload = (event, fieldName, setFieldValue) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      const fileType = file.type.split("/")[1];
      console.log(file.name);
      console.log(fileType);

      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        const base64String = reader.result.split("base64,")[1];
        console.log(base64String);
        setFieldValue(fieldName, base64String);
        setUploadStatus((prevState) => ({
          ...prevState,
          [fieldName]: true,
        }));
      };

      reader.onerror = function (error) {
        console.error("Error reading file: ", error);
      };
    } else {
      console.log("No file selected.");
    }
  };

  return (
    <>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleChange, setFieldValue, values }) => (
            <Form>
              <Grid2 container size={12} spacing={"1rem"}>
                <Grid2 container size={12} alignItems={"center"}>
                  <Grid2 container size={4}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        display: "flex",
                        alignItems: "cente",
                        p: 1,
                        cursor: "pointer",
                      }}
                      onClick={handleCloseClick}
                    >
                      <ArrowBack color={commonCss.green} />
                    </Box>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"center"}>
                    <Typography variant="h5" fontWeight="bold" color="white">
                      Method Statement
                    </Typography>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"flex-end"}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        p: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Permit To Work No.{" "}
                        <span style={{ color: "#e53e3e" }}>2701</span>
                      </Typography>
                    </Box>
                  </Grid2>
                  <Divider
                    sx={{
                      borderColor: commonCss.red,
                      borderWidth: 1,
                      width: "100%",
                    }}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Typography
                    variant="body2"
                    color={commonCss.outSideSentenceColor}
                  >
                    The Permit Issuer, when developing the respective Permit
                    forms, will use the Method Statement supplied.
                  </Typography>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Company
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="company"
                          value={values.company}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Project
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="project"
                          value={values.project}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date and Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              value={
                                values.datetime !== null
                                  ? dayjs(values.datetime)
                                  : null
                              }
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue("datetime", dateFrom);

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          Description of works
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Duration of work?
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="durationOfWork"
                          value={values.durationOfWork}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Include site location drawings if appropriate?
                          </Typography>
                        </InputLabel>

                        <Button
                          variant="contained"
                          component="label"
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                          }}
                        >
                          <FileUploadOutlined />{" "}
                          <Typography sx={{ fontSize: "0.9rem" }}>
                            {uploadStatus.siteLocationDrawings
                              ? "Attachment was Uploaded"
                              : "Add Attachment"}
                          </Typography>
                          <input
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                "siteLocationDrawings",
                                setFieldValue
                              )
                            }
                          />
                        </Button>
                      </Grid2>

                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Are there any restrictions(e.g overhead power
                            lines)?
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="restrictions"
                          value={values.restrictions}
                          onChange={(e) =>
                            setFieldValue("restrictions", e.target.value)
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Any local permit required (local regulations)
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="localPermitRequired"
                          value={values.localPermitRequired}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Any other details
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="otherWorkDetails"
                          value={values.otherWorkDetails}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          Names of Responsible persons
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Who is going to be working on the project?
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="projectWorkerDetails"
                          value={values.projectWorkerDetails}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Who will be in charge?
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="inChargeDetails"
                          value={values.inChargeDetails}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Who has specific responsibilities?(eg. plant ,
                            material, etc)
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="responsibilitiesSpecified"
                          value={values.responsibilitiesSpecified}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Who is to make contact with local authorities?
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="localAuthoritiesContact"
                          value={values.localAuthoritiesContact}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Any other details
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="otherPersonDetails"
                          value={values.otherPersonDetails}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          Plant and Equipment
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            What plant equipment and tools are required to do
                            the job?
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="plantEquipmentRequired"
                          value={values.plantEquipmentRequired}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            Tools (for work)
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="workingTools"
                          value={values.workingTools}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 12 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Tool ( that may generate spark, ignitions source,
                            etc)
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="sparkTools"
                          value={values.sparkTools}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Others, Specify
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="othersTools"
                          value={values.othersTools}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            What certificates, if any , are required for plant
                            or equipment?
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="certificatesRequired"
                          value={values.certificatesRequired}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            What training, if any, is required for operatives?
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="trainingRequired"
                          value={values.trainingRequired}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          Communications with the workforce
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            How are the procedures, hazards to be communicated
                            to the workforce?
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="proceduresCommunicatedWorkforce"
                          value={values.proceduresCommunicatedWorkforce}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Start Date
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              value={
                                values.proposedStartDate !== null
                                  ? dayjs(values.proposedStartDate)
                                  : null
                              }
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "proposedStartDate",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            End Date
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              value={
                                values.proposedEndDate !== null
                                  ? dayjs(values.proposedEndDate)
                                  : null
                              }
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue("proposedEndDate", dateFrom);

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:
                                    commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 8 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            How work to be completed
                          </Typography>
                        </InputLabel>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={workComplete} // This should control the input field
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                            onChange={(e) => {
                              setWorkComplete(e.target.value);
                            }} // Update workComplete state for individual steps
                          />
                          <IconButton
                            sx={{ border: `3px solid ${commonCss.red}`, p: 0 }}
                            onClick={handleWC}
                          >
                            <Add sx={{ color: commonCss.red }} />
                          </IconButton>
                        </Box>

                        {workCompleteData.length !== 0 && (
                          <Grid2 size={7}>
                            <Paper elevation={4} sx={{p:1}}>
                              {workCompleteData.map((comData, i) => (
                                <Box sx={{display:"flex",alignItems:"center",width:"100%",justifyContent:"space-between"}}>
                                  <Typography>{comData}</Typography>
                                  <IconButton
                                    onClick={() => {
                                      setEditWC({ bol: true, i: i });
                                      setWorkComplete(comData);
                                    }} 
                                  >
                                    <EditOutlined
                                      sx={{ color: commonCss.red }}
                                    />
                                  </IconButton>
                                </Box>
                              ))}

                              {/* <NormalTable
                                data={workCompleteData}
                                columns={workColumns}
                                hideHeader={true}
                              /> */}
                            </Paper>
                          </Grid2>
                        )}
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12} justifyContent={"flex-end"}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: commonCss.green,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                    }}
                  >
                    Submit
                  </Button>
                </Grid2>
              </Grid2>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default MethodStatement;
