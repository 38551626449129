import React from "react";

import { Route, Routes } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Pages/Sigin/Login";
import { routerLink } from "./ProtectedRoutes/routes";
import ProtectedRoute from "./ProtectedRoutes/ProtectedRoute";
import ViewImage from "./Pages/Form/ViewImage";

function App() {
  const theme = createTheme({
    // typography: {
    //   fontFamily:"'Nunito', sans-serif", // Change the font family to Open Sans
    // },
    palette: {
      
      // secondary: {
      //   main: "#FFFFFF", //nav
      // },
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/image" element={<ViewImage />} />
       
      {routerLink.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={
              <ProtectedRoute data={route}>
                <route.component />
              </ProtectedRoute>
            }
          />
        ))}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      </ThemeProvider>
  );
}

export default App;
