import ConfinedSpaceCertificate from "../Pages/Form/Certificate/ConfinedSpaceCertificate";
import HotWork from "../Pages/Form/Certificate/HotWork";
import IsolationCertificate from "../Pages/Form/Certificate/IsolationCertificate";
import RadiationCertificate from "../Pages/Form/Certificate/RadiationCertificate";
import FormsList from "../Pages/Form/FormsList";
import MainForm from "../Pages/Form/MainForm";
import PTWForm1 from "../Pages/Form/PTWForm1";
import PTWForm2 from "../Pages/Form/PTWForm2";
import Company from "../Pages/Master/Company/Company";
import Role from "../Pages/Master/Role/Role";
import Site from "../Pages/Master/Site/Site";
import Terminal from "../Pages/Master/Terminal/Terminal";
import User from "../Pages/Master/User/User";

export const routerLink = [
  {
    path: "/formlist",
    component: FormsList,
    // module: "notauthenticate",
  },
  {
    path: "/ptwform1",
    component: PTWForm1,
  },
  {
    path: "/ptwform2",
    component: PTWForm2,
  },
  {
    path: "/terminal",
    component: Terminal,
  },
  {
    path: "/site",
    component: Site,
  },
  {
    path: "/user",
    component: User,
  },
  // {
  //   path: "/role",
  //   component: Role,
  // },
  {
    path: "/company",
    component: Company,
  },
];
