import React, { Fragment, useEffect, useState } from "react";
import {
  Grid2,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  FormControl,
  RadioGroup,
  Radio,
  List,
  ListItem,
  Paper,
  MenuItem,
  Button,
  Select,
  Container,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Divider,
  InputLabel,
  FormGroup,
  Dialog,
  Collapse,
  DialogContent,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

import NormalTable from "../../Component/NormalTable";
import { Add, AddCircleOutline, DeleteOutline, EditOutlined, KeyboardArrowDown, KeyboardArrowUp, Minimize, PlusOne, UploadFile } from "@mui/icons-material";
import { commonCss } from "../../StyleConfig/CommonCss";
import CertificateSelect from "./CertificateSelect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import RadiationCertificate from "./Certificate/RadiationCertificate";
import LabourSelect from "./LabourSelect";
import MethodStatement from "./Forms/MethodStatement";
import RiskAssessment from "./Forms/RiskAssessment";
import IsolationCertificate from "./Certificate/IsolationCertificate";
import LiftingAndHoisting from "./Certificate/LiftingAndHoisting";
import SafeOverride from "./Certificate/SafeOverride";
import Excavation from "./Certificate/Excavation";
import ConfinedSpaceCertificate from "./Certificate/ConfinedSpaceCertificate";
import HotWork from "./Certificate/HotWork";
import { api, companyInfo,  infoId,  roleInfo, terminalInfo } from "../API/config";
import { axiosPrivate } from "../../axios/axios";
import Loading from "../../Component/Loading";
import { formDataDispatch } from "../../Redux/data";
import { Form, Formik } from "formik";

const PTWForm1 = () => {
  const login = useSelector((state) => state.auth.loginInfo);
  const [searchParams,setSearchParams]=useSearchParams()
  
  const [data,setData]=useState({})
  const [location,setLocation]=useState("")
  const [loading,setLoading]=useState(false)
  const [holderName,setHolderName]=useState({
    ptwHolderName:"",
    companyName:"",
    technicianName:"",
    workingAreaTechnicianName:"",
    supEmail:""
  })
  
  const [certificateInfo,setCertificateInfo]=useState({})
  const [certificateAddList,setCertificateAddList]=useState({})
  const [certificateListOpen,setCertificateListOpen]=useState({
        hotWork:false,
        confinedSpace:false,
        radiation:false,
        excavation:false,
        isolation:false,
        safe:false,
        lifting:false
  })
  const [certificateDataList,setCertificateDataList]=useState({
    enableHotWorkCertificate:[],
    enableConfinedSpaceCertificate:[],
    enableSystemOverridingCertificate:[],
    enableExcavationCertificate:[],
    enableIsolationCertificate:[],
    enableLiftingAndHoistingCertificate:[],
    enableRadiationCertificate:[],

  })
  const [terminal,setTerminal]=useState([])
  const [site,setSite]=useState([])
  const [formTypeData,setFormTypeData]=useState([])
  const [technician,setTechnician]=useState([])
  const [riskLevel,setRiskLevel]=useState([])
  const [riskLevelBol,setRiskLevelBol]=useState({
    isRiskAssessmentRequired:false,
    isMethodStatementRequired:false,
    jmsDocuments:"",
    jsaDocuments:"",
    riskLevelId:null,
    listJSM:false,
    listJSA:false,
    listRiskAssementRequired:false,
    listMethod:false
  })
  const [classifiedWhere,setClassifiedWhere]=useState([])
  const [classifiedWithWhat,setClassifiedWithWhat]=useState([])
  const [oiWhere,setOiWhere]=useState([])
  const [apWhere,setAPWhere]=useState([])
  const [productInvolved,setProductInvolved]=useState([])
  
  const [selectedOptions, setSelectedOptions] = useState({
    excavation: false,
    enteringConfinedSpace: false,
    assemble: false,
    disassemble: false,
    prefabrication: false,
    openingConfinedSpace: false,
    welding: false,
    workingAtHeight: false,
    cutting: false,
    drillingGrinding: false,
    highPressureJetting: false,
    gritBlasting: false,
    xrayRadiography: false,
    liftingCraneActivities: false,
    calibration: false,
    electricalActivities: false,
    nitrogenPressureTest: false,
    hydrostaticTest: false,
    other: true,
  });
  

  const [certificateOpen,setCertificateOpen]=useState(false)
 
  const [labourList,setLabourList]=useState([])
    
    const [labour,setLabour]=useState("")
    const [editLabour,setEditLabour]=useState({bol:false,i:null})
  const [oiOthers,setOIOthers]=useState(false)
  const [workAreaOthers,setWorkAreaOthers]=useState(false)
  
  const [locationBol,setLocationBol]=useState(false)


  const [dataInfo,setDataInfo]=useState({
    description:"",
    location:""
  })

 

  const navigate=useNavigate()
  const dispatch=useDispatch()

  useEffect(()=>{
    getData()
    getDetails()
  },[])
  const getData=async()=>{
    
    const getDetailByid=api.PTWForm.getDetailById
    await axiosPrivate.get(`${getDetailByid}/${searchParams.get("id")}`).then(res=>{
     setData(res.data.data)
     setLocation(res.data.data.location)
     setHolderName(
      {
        ptwHolderName:res.data.data.permitHolderId!==null?res.data.data?.permitHolderUser?.firstName+" "+res.data.data?.permitHolderUser?.firstName:login.firstName+" "+login.lastName,
        companyName:res.data.data.companyId!==null?res.data.data?.companyDetail?.name:login.companyName,
        technicianName:res.data.data.technicianId!==null?res.data.data?.technicianDetail?.name:"",
        supEmail:res.data.data?.workingArea?.pspSupervisorId!==null?res.data.data?.workingArea?.pspSupervisorDetail?.emailId:""
      }
     )
     setRiskLevelBol(
      {
        ...riskLevelBol,
        jmsDocuments:res.data.data?.formDetail?.jmsDocuments,
        jsaDocuments:res.data.data?.formDetail?.jsaDocuments,
        riskLevelId:res.data.data.riskLevelId,
        listJSM:res.data.data?.formDetail?.isJMSRequired,
        listJSA:res.data.data?.formDetail?.isJSARequired,
        listRiskAssementRequired:res.data.data?.formDetail?.isRiskAssessmentRequired,
        listMethod:res.data.data?.formDetail?.isMethodStatementRequired
      }
     )
      console.log(res)
    }).catch(e=>{
      setData({})
      console.log(e,"MainForm")
    })
  }
  const getDetails=async()=>{
    setLoading(true)
    const getTerminal=api.terminal.getAll
    await axiosPrivate.get(`${getTerminal}`).then(res=>{
      setTerminal(res.data.data)
    }).catch(e=>{
      setTerminal([])
      console.log(e)
    })
    const getFormTypeLink=api.formType.getAll
    await axiosPrivate.get(`${getFormTypeLink}`).then(res=>{
      setFormTypeData(res.data.data)
    }).catch(e=>{
      setFormTypeData([])
      console.log(e)
    })
    const getTechnican=api.user.getUserByCompanyIdAndRoleId
    await axiosPrivate.get(`${getTechnican}?companyId=${companyInfo.ps}&roleId=${roleInfo.technican}`).then(res=>{
      setTechnician(res.data.data)
    }).catch(e=>{
      setTechnician([])
      console.log(e)
    })
    const getRiskLevel=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getRiskLevel}?keyName=RiskLevel`).then(res=>{
       setRiskLevel(res.data.data)
    }).catch(e=>{
      setRiskLevel([])
      console.log(e)
    })
    const getClassifiedWhere=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getClassifiedWhere}?keyName=ClassifiedAreaWhere`).then(res=>{
       setClassifiedWhere(res.data.data)
    }).catch(e=>{
      setClassifiedWhere([])
      console.log(e)
    })
    const getClassifiedWithWhat=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getClassifiedWithWhat}?keyName=ClassifiedAreaWithWhat`).then(res=>{
       setClassifiedWithWhat(res.data.data)
    }).catch(e=>{
      setClassifiedWithWhat([])
      console.log(e)
    })
    const getOIWhere=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getOIWhere}?keyName=OperationalInformationWhat`).then(res=>{
       setOiWhere(res.data.data)
    }).catch(e=>{
      setOiWhere([])
      console.log(e)
    })
    const getAPWhere=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getAPWhere}?keyName=AdditionalPPEWhere`).then(res=>{
       setAPWhere(res.data.data)
    }).catch(e=>{
      setAPWhere([])
      console.log(e)
    })
    const getProductInvl=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getProductInvl}?keyName=ProductInvolvedClass`).then(res=>{
       setProductInvolved(res.data.data)
    }).catch(e=>{
      setProductInvolved([])
      console.log(e)
    })
    setLoading(false)
  }

  const initialValue={
    ...data,
    id:data.id,
    terminalId: data.terminalId,
    siteId: data.siteId,
    locationId: data.locationId,
    formTypeId: data.formTypeId,
    workDescription:data.workDescription,
    technicianId:data.technicianId,
    riskLevelId:data.riskLevelId,
    companyId:data.companyId,
    permitHolderId:data.permitHolderId!==null?data.permitHolderId:login.userId,
    formDetail: {
    formId: data.id,
    isRiskAssessmentRequired: data?.formDetail?.isRiskAssessmentRequired,
    isMethodStatementRequired: data?.formDetail?.isMethodStatementRequired,
    isJMSRequired: data?.formDetail?.isJMSRequired,
    isJSARequired: data?.formDetail?.isJSARequired,
    jmsDocuments: data?.formDetail?.jmsDocuments,
    jsaDocuments: data?.formDetail?.jsaDocuments,
    classifiedAreaWhere: String(data?.formDetail?.classifiedAreaWhere).split(','),
    classifiedAreaWithWhat: String(data?.formDetail?.classifiedAreaWithWhat).split(','),
    classifiedAreaAnyOther: data?.formDetail?.classifiedAreaAnyOther,
    operationalInformationWhat: String(data?.formDetail?.operationalInformationWhat).split(','),
    productInvolvedClassId:data?.formDetail?.productInvolvedClassId,
    productInvolvedOtherDesc: data?.formDetail?.productInvolvedOtherDesc,
    additionalPPPEWhere: String(data?.formDetail?.additionalPPPEWhere).split(','),
    specialclothingSpecified:data?.formDetail?.specialclothingSpecified,
    locationText:data?.formDetail?.locationText
    },
    workingArea: {
    formId: data.id,
    demarcation: data?.workingArea?.demarcation,
    fireExtinguisher: data?.workingArea?.fireExtinguisher,
    useOfWarningSigns: data?.workingArea?.useOfWarningSigns,
    otherSpecified: data?.workingArea?.otherSpecified,
    otherSpecifiedText: data?.workingArea?.otherSpecifiedText,
    technicianId: data?.workingArea?.technicianId,
    pspSupervisorId: data?.workingArea?.pspSupervisorId!==undefined&&null?data?.workingArea?.pspSupervisorId:"aa1f97a4-4c05-4344-85d3-08dceebb87aa"
  },
  operationalMeasures: {
    formId: data.id,
    shutdownOP: data?.operationalMeasures?.shutdownOP,
    fireprotectionOP: data?.operationalMeasures?.fireprotectionOP,
    automaticfireprotectionOP: data?.operationalMeasures?.automaticfireprotectionOP,
    otherMeasures: data?.operationalMeasures?.otherMeasures,
    electrical: {
      omId: data?.operationalMeasures?.electrical.omId,
      equipment: data?.operationalMeasures?.electrical.equipment,
      tagNO: data?.operationalMeasures?.electrical.tagNO,
      lockedoff: data?.operationalMeasures?.electrical.lockedoff,
      isolated: data?.operationalMeasures?.electrical.isolated
    },
    instrumentation: {
      omId: data?.operationalMeasures?.instrumentation.omId,
      equipment: data?.operationalMeasures?.instrumentation.equipment,
      tagNO: data?.operationalMeasures?.instrumentation.tagNO,
      lockedoff: data?.operationalMeasures?.instrumentation.lockedoff,
      isolated: data?.operationalMeasures?.instrumentation.isolated,
      disconnected: data?.operationalMeasures?.instrumentation.disconnected,
      bypass: data?.operationalMeasures?.instrumentation.bypass
    },
    mechanical: {
      omId: data?.operationalMeasures?.mechanical.omId,
      equipment: data?.operationalMeasures?.mechanical.equipment,
      tagNO: data?.operationalMeasures?.mechanical.tagNO,
      pressurefree: data?.operationalMeasures?.mechanical.pressurefree,
      empty: data?.operationalMeasures?.mechanical.empty,
      disconnected: data?.operationalMeasures?.mechanical.disconnected,
      blankedoff: data?.operationalMeasures?.mechanical.blankedoff,
      lockedoff: data?.operationalMeasures?.mechanical.lockedoff,
      flushed: data?.operationalMeasures?.mechanical.flushed,
      ventilationSpecified: data?.operationalMeasures?.mechanical.ventilationSpecified
    }
  },
  }

  const updateCertificate=async()=>{
    const getDetailByid=api.PTWForm.getDetailById
    await axiosPrivate.get(`${getDetailByid}/${searchParams.get("id")}`).then(res=>{
      setData({...data,
        enableHotWorkCertificate: res.data.data.enableHotWorkCertificate,
  enableConfinedSpaceCertificate: res.data.data.enableConfinedSpaceCertificate,
  enableLiftingAndHoistingCertificate: res.data.data.enableLiftingAndHoistingCertificate,
  enableRadiationCertificate: res.data.data.enableRadiationCertificate,
  enableIsolationCertificate: res.data.data.enableIsolationCertificate,
  enableExcavationCertificate: res.data.data.enableExcavationCertificate,
  enableSystemOverridingCertificate: res.data.data.enableSystemOverridingCertificate,
      })
      }).catch(e=>{
        console.log(e)
      })
  }
  



  const certificateName=[
    {
        name:"enableHotWorkCertificate",
        label:"Hot Work",
        api:"hotWork"
    },
    {
        name:"enableConfinedSpaceCertificate",
        label:"Confined Space",
        api:"confinedSpace"
    },
    {
        name:"enableSystemOverridingCertificate",
        label:"System Overriding",
        api:"systemOverriding"
    },
    {
        name:"enableExcavationCertificate",
        label:"Excavation",
        api:"excavation"
    },
    {
        name:"enableIsolationCertificate",
        label:"Isolation",
        api:"isolation"
    },
    {
        name:"enableLiftingAndHoistingCertificate",
        label:"Lifting & Hoisting",
        api:"liftingAndHoisting"
    },
    {
        name:"enableRadiationCertificate",
        label:"Radiation",
        api:"radiation"
    },
]
  const certificateColumns=[
    {
      Header:"No",
      accessor: "no",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {Number(cell.row.id)+1}
          </Typography>
        );
      },
      // disableFilters: true,
    },
    {
      Header:"Status",
      accessor: "Status",
      // disableFilters: true,
    },
    {
      Header:"Validity",
      accessor: "validity",
      // disableFilters: true,
    },
    {
      accessor:"id",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:"center" }}>
          
            <IconButton onClick={()=>{
              setCertificateInfo(cell.row.original)
              if(cell.row.original.type==="enableHotWorkCertificate"){
                setCertificateListOpen({...certificateListOpen,hotWork:true})
              }else if(cell.row.original.type==="enableConfinedSpaceCertificate"){
                setCertificateListOpen({...certificateListOpen,confinedSpace:true})
              }
              else if(cell.row.original.type==="enableRadiationCertificate"){
                setCertificateListOpen({...certificateListOpen,radiation:true})
              }
              else if(cell.row.original.type==="enableExcavationCertificate"){
                setCertificateListOpen({...certificateListOpen,excavation:true})
              }
              else if(cell.row.original.type==="enableIsolationCertificate"){
                setCertificateListOpen({...certificateListOpen,isolation:true})
              }
              else if(cell.row.original.type==="enableSystemOverridingCertificate"){
                setCertificateListOpen({...certificateListOpen,safe:true})
              }else{
                setCertificateListOpen({...certificateListOpen,lifting:true})
              }
            }}>
              <EditOutlined sx={{color:commonCss.red}}
              
              />
            </IconButton>
            <IconButton sx={{}}>
              <Minimize sx={{color:commonCss.red}}/>
            </IconButton>
           
          </Box>
        );
      },
    }
    
  ]
  const riskLevelColumns=[
    {
      accessor: "label",
      // disableFilters: true,
    },
    {
      accessor:"id",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
          
            <IconButton sx={{display:cell.row.original.label!=="JMS"&&cell.row.original.label!=="JSA"?"default":"none"}}
            onClick={()=>{
                setRiskLevelBol({...riskLevelBol,[cell.row.original.name]:true})
           
            }}
            >
              <EditOutlined sx={{color:commonCss.red}}/>
              
            </IconButton>
            <IconButton component={"label"} sx={{display:cell.row.original.label==="JMS"||cell.row.original.label==="JSA"?"default":"none"}}>
              <UploadFile sx={{color:commonCss.red}}/>
              <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    if (e.target.files[0] !== undefined) {
                      const file = e.target.files[0];
                      let reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = function () {
                        const dataSplit = reader.result.split("base64,")[1];
                        // Process the base64 data here
                        setRiskLevelBol({...riskLevelBol,[cell.row.original.docName]:dataSplit})
                      };
                      reader.onerror = function (error) {};
                    }
                  }}
                />
            </IconButton>
           
          </Box>
        );
      },
    }
    
  ]
  const riskLevelTypes=[
    {
      name:"isRiskAssessmentRequired",
      label:"Risk Assesment",
      listName:'listRiskAssementRequired',
      flag:riskLevelBol.riskLevelId===infoId.low,
      listLevel:riskLevelBol.listRiskAssementRequired,
    },
    {
      name:"isMethodStatementRequired",
      label:"Method Statement",
      listName:'listMethod',
      flag:riskLevelBol.riskLevelId===infoId.low,
      listLevel:riskLevelBol.listMethod,
    },
    {
      name:"isJMSRequired",
      label:"JMS",
      listName:'listJSM',
      flag:riskLevelBol.riskLevelId!==null,
      listLevel:riskLevelBol.listJSM,
      docName:"jmsDocuments"
    },
    {
      name:"isJSARequired",
      label:"JSA",
      flag:riskLevelBol.riskLevelId!==null,
      listName:'listJSA',
      listLevel:riskLevelBol.listJSA,
      docName:"jsaDocuments"
    },
  ]
  const workingArea=[
    {
      name:"demarcation",
      label:"Demarcation"
    },
    {
      name:"fireExtinguisher",
      label:"Fire Extinguisher"
    },
    {
      name:"useOfWarningSigns",
      label:"Use of Warning Signs"
    },
    {
      name:"otherSpecified",
      label:"Others"
    },
    
  ]
  const mechnicalOM=[
    {
      name:"pressurefree",
      label:"Pressure free"
    },
    {
      name:"empty",
      label:"Empty"
    },
    {
      name:"disconnected",
      label:"Disconnected"
    },
    {
      name:"blankedoff",
      label:"Blanked off"
    },
    {
      name:"lockedoff",
      label:"Locked off"
    },
    {
      name:"flushed",
      label:"Flushed"
    },
    
  ]
  const electricalOM=[
    {
      name:"lockedoff",
      label:"Locked off"
    },
    {
      name:"isolated",
      label:"Isolated"
    },
    
  ]
  const instrumentationOM=[
    {
      name:"lockedoff",
      label:"Locked off"
    },
    {
      name:"isolated",
      label:"Isolated"
    },
    {
      name:"disconnected",
      label:"Disconnected"
    },
    {
      name:"bypass",
      label:"Bypass"
    },
    
  ]
  const measureList=[
    {
      name:"shutdownOP",
      label:"Shut down system operational"
    },
    {
      name:"fireprotectionOP",
      label:"Fire protection system operational"
    },
    {
      name:"automaticfireprotectionOP",
      label:"Automatic fire protection system operational"
    },
    
    
  ]


  const labourColumns= [
    // {
    //   Header:"Code",
    //   accessor: "code",
    //   // disableFilters: true,
    // },
    {
      Header:"Labour Name",
      accessor: "name",
      // disableFilters: true,
    },
    {
      accessor:"id",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
          
            <IconButton >
              <DeleteOutline sx={{color:commonCss.red}}/>
            </IconButton>
           
           
          </Box>
        );
      },
    }
    
  ]


  


  
  

  

  



  
 
  


  const handleStaff=()=>{
    if(editLabour.bol){
     let updateLabour=[...labourList] 
     updateLabour[editLabour.i]={name:labour}
     setLabourList(updateLabour)
     setEditLabour({bol:false,i:null})
     setLabour("")
    }else{
     setLabourList([...labourList,{name:labour}])
     setLabour("")
    }
 }

 const handleCertificate=async(nameCert)=>{
  const id= searchParams.get("id")
  if(certificateAddList[nameCert.name]){
    setCertificateAddList({...certificateAddList,[nameCert.name]:false})
  }else{
  const getLink=api[nameCert.api].getDataByFormId
  await axiosPrivate.get(`${getLink}?formId=${id}`).then(res=>{
    setCertificateAddList({...certificateAddList,[nameCert.name]:certificateAddList[nameCert.name]?false:true})
    setCertificateDataList({...certificateDataList,[nameCert.name]:res.data.data})
    console.log(res)
  }).catch(e=>{
    console.log(e)
    setCertificateAddList({...certificateAddList,[nameCert.name]:false})
    setCertificateDataList({...certificateDataList,[nameCert.name]:[]})
  })
}


 }

 const getSite=async(value)=>{
 const getSiteLink=api.site.getAll
 await axiosPrivate.get(`${getSiteLink}`).then(res=>{
  setSite(res.data.data)
 }).catch(e=>{
  console.log(e)
 })
 }

//  const handleInitialValue=async(name,value,form)=>{
//   if(form==="formDetail"){
//     setInitialValue({...initialValue,formDetail:{...initialValue.formDetail,[name]:value}})
//   }else if(name==="terminalId"&&value===terminalInfo.terminal1){
//    await getSite(value)
//   }else if(name==="riskLevelId"){
//     setInitialValue({...initialValue,[name]:value,formDetail:{...initialValue.formDetail,
//       isRiskAssessmentRequired: false,
//       isMethodStatementRequired: false,
//       isJMSRequired: false,
//       isJSARequired: false,
//       jmsDocuments: null,
//       jsaDocuments: null}})
//   }else{
//     setInitialValue({...initialValue,[name]:value})
//   }
//  }

 const onSubmit=async(value)=>{
  
  const saveFormLink=api.PTWForm.saveForm
  const postData={...value,
    formDetail:{
      ...value.formDetail,
      classifiedAreaWhere:value.formDetail.classifiedAreaWhere.length!==0?value.formDetail.classifiedAreaWhere.toString():null,
      classifiedAreaWithWhat:value.formDetail.classifiedAreaWithWhat.length!==0?value.formDetail.classifiedAreaWithWhat.toString():null,
      operationalInformationWhat:value.formDetail.operationalInformationWhat.length!==0?value.formDetail.operationalInformationWhat.toString():null,
      additionalPPPEWhere:value.formDetail.additionalPPPEWhere.length!==0?value.formDetail.additionalPPPEWhere.toString():null,
    }}
   console.log(postData)
  await axiosPrivate.post(`${saveFormLink}`,postData).then(res=>{
    console.log(res)
     navigate(`/ptwForm2?id=${searchParams.get("id")}&no=${searchParams.get("no")}`)
   }).catch(e=>{
    console.log(e)
   })
  
 }



  return (
    <>
    {loading?<Loading/>:
   
      <Formik initialValues={initialValue} onSubmit={onSubmit} enableReinitialize>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
             <Paper
      sx={{
        
        display:"flex",
        flexDirection:"column",
        gap:"0.6rem",
        background: 'linear-gradient(to right, #DAF2F2, #FEDADA)',
        borderRadius: "10px",
        p:1.5,
      }}
    >
        <Grid2 container size={12} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="#00807F">
            PSP PERMIT TO WORK FORM
          </Typography>
        </Grid2>
        <Grid2 container size={12} >
          <Grid2 size={{xs:6}}>
            <Typography variant="body1" fontWeight="bold">
            Date of issue : 6/8/2024
            </Typography>
          </Grid2>
          
          <Grid2 container size={{xs:6}} justifyContent={"flex-end"}>
            <Typography variant="body1" fontWeight="bold">
            No. <span style={{ color: "#FD0000",fontSize:"1.2rem" }}>{searchParams.get("no")}</span>
            </Typography>
          </Grid2>
          <Divider sx={{ borderColor: "#FD0000", borderWidth: 1,width:"100%" }} />
        </Grid2>
        <Grid2 container size={12} alignItems={"center"} spacing={"1rem"}>
        <Grid2 size={{xs:12,md:4}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Terminal
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                name="terminalId"
                value={values.terminalId}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={(e)=>{
                  handleChange(e)
                  if(terminalInfo.terminal1===e.target.value){
                    getSite(e.target.value)
                  }
                  
                }}
                fullWidth
              >
                {terminal.map((term,i)=>(
                    <MenuItem  key={i} value={term.id}>{term.name}</MenuItem>
                ))}
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:4}} sx={{display:values.terminalId===terminalInfo.terminal1?"default":"none"}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Site
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="siteId"
                value={values.siteId}
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={handleChange}
                fullWidth
              >
                {site.map((siteList,i)=>(
                 <MenuItem key={i} value={siteList.id}>{siteList.name}</MenuItem>
                ))}
                
                
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:4}} sx={{display:values.terminalId!==null?"default":"none"}}>
            <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Location
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="formDetail.locationText"
              value={values.formDetail.locationText}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white', 
                  borderRadius:"13px"
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
                },
              }}
              onChange={(e)=>{
                setFieldValue("formDetail.locationText",e.target.value)
                setLocation(e.target.value)
              }}
            />
          </Grid2>
          <Grid2 container size={{xs:12,md:4}} justifyContent={"flex-start"} mt={1.8} sx={{display:values.terminalId===terminalInfo.terminal1?"default":"none"}}>
           <FormControl>
                <RadioGroup
                  name="formTypeId"
                  row
                  value={values.formTypeId}
                  onChange={handleChange}
                >
                  {formTypeData.map((formTypeDat,i)=>(
                    <FormControlLabel
                    value={formTypeDat.id}
                    
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fill: "#00807F",
                          },
                          "&:checked": {
                            color: "#00807F",
                          },
                          
                        }}
                      />
                    }
                    label={formTypeDat.name}
                  />
                  ))}
                  
                </RadioGroup>
              </FormControl>
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Technician
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="technicianId"
                value={values.technicianId}
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                fullWidth
                onChange={handleChange}
              >
                {technician.map((userTech,i)=>(
                  <MenuItem key={i} value={userTech.id} onClick={()=>{setHolderName({...holderName,technicianName:userTech.firstName+" "+userTech.lastName})}}>{userTech.firstName+" "+userTech.lastName}</MenuItem>
                ))}
              </Select>
          </Grid2>
          
        <Grid2 size={{xs:12,md:4}}>
        <InputLabel sx={{pl:0.5}}>
            <Typography variant="body2" fontWeight="500" color="black">
              Description of work
            </Typography>
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="workDescription"
            value={values.workDescription}
            multiline
            maxRows={3}

            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
            onChange={handleChange}
          />
       
        </Grid2>
        </Grid2>
        <Grid2 container size={12} rowSpacing={"1rem"} columnSpacing={"2rem"} alignItems={"center"} >
          <Grid2  size={{xs:12,md:4}}>
            <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Risk Level
              </Typography>
            </InputLabel>
            <Select
               fullWidth
                size="small"
                defaultValue=""
                displayEmpty
                value={values.riskLevelId}
                name="riskLevelId"
                inputProps={{ "aria-label": "Risk Level" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
                },
                  
                }}
                onChange={(e)=>{
                  handleChange(e)
                  setRiskLevelBol({...riskLevelBol,
                    riskLevelId:e.target.value,
                    listJSM:false,
                    listJSA:false,
                    listRiskAssementRequired:false,
                    listMethod:false})

                }}
              >
                {riskLevel.map((level,i)=>(
                   <MenuItem key={i} value={level.id}>{level.name}</MenuItem>
                ))}
            </Select>

          </Grid2>
          <Grid2 container size={8} spacing={1} alignItems="center" sx={{display:values.riskLevelId!==null?"default":"none",mt:2}}>
          
          <FormGroup row >
            {riskLevelTypes.map((typeLevel,i)=>(
              <>
               <FormControlLabel 
               key={i}
               sx={{display:typeLevel.flag?"default":"none", ml:1.5}}
               control={
               <Checkbox 
               name={typeLevel.name} 
               checked={values.formDetail[typeLevel.name]}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                setFieldValue(`formDetail.${typeLevel.name}`,e.target.checked)
                setRiskLevelBol({...riskLevelBol,[typeLevel.listName]:e.target.checked})
              }}
             />
               
               } label={typeLevel.label} />
               </>
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={6} sx={{display:values.formDetail.isRiskAssessmentRequired||values.formDetail.isMethodStatementRequired||values.formDetail.isJMSRequired||values.formDetail.isJSARequired?"default":"none"}}>
            <Paper elevation={4}>
            <NormalTable data={riskLevelTypes.filter(fil=>fil.listLevel)} columns={riskLevelColumns} hideHeader={true}  />
            </Paper>
          </Grid2>
        </Grid2>
        <Grid2 container size={12} alignItems={"center"} spacing={1}>
          <Grid2 size={6}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Appendix – Associated Certificate
              </Typography>
              </InputLabel>
          </Grid2>
          <Grid2 size={6}>
            <Box sx={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
            <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{setCertificateOpen(true)}}
          >
            Add Certificate
          </Button>
            </Box>
          </Grid2>
          <Grid2 size={12}>
            
            {certificateName.map((nameCertificate,i)=>(
              <>
              <Box sx={{display:data[nameCertificate.name]?"default":"none"}}>
              <Box sx={{display:"flex",justifyContent:'space-between',alignItems:"center"}}>
                <Box sx={{cursor:"pointer",display:"flex",alignItems:"center"}}
                onClick={()=>{
                   handleCertificate(nameCertificate)
                }}
                >
                  <IconButton>
                    {certificateAddList[nameCertificate.name]?<KeyboardArrowUp sx={{color:commonCss.green}}/>:<KeyboardArrowDown sx={{color:commonCss.green}}/>}
                  </IconButton>
                <Typography sx={{fontSize:"0.9rem",
                  fontWeight:500,
                  color:commonCss.green,
                  cursor:'pointer'
                  }}

                  >
                  {nameCertificate.label}
                </Typography>
                </Box>
                <IconButton>
                  <AddCircleOutline sx={{color:commonCss.red}}/>
                </IconButton>
              </Box>
              <Divider sx={{width:"100%"}} />
              
              <Paper elevation={4} sx={{p:1,display:certificateAddList[nameCertificate.name]?"default":"none"}}>
               <Collapse in={certificateAddList[nameCertificate.name]} >
                <NormalTable 
                data={certificateDataList[nameCertificate.name].map(obj => {
                            return { ...obj, type: nameCertificate.name };
                             })} 
                columns={certificateColumns} />
               </Collapse >
               </Paper>
               </Box>
              </>
            ))}
         
          </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
            PERMIT HOLDER
          </Typography>
          </Grid2>
          <Grid2 size={{xs:12,md:3.5}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Name
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.ptwHolderName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:3.5}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Company
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.companyName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          {/* <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                No.of Staff Present
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={labourList.length}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> */}
          {/* <Grid2 container size={12} alignItems={"center"} spacing={1}>
          <Grid2 size={3}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Staff List
              </Typography>
              </InputLabel>
          </Grid2>
          <Grid2 size={4}>
            <Box sx={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
            <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{setLabour(true)}}
          >
            Add Staff
          </Button>
            </Box>
          </Grid2>
          <Grid2 size={7}>
            <Paper elevation={4} >
             
                <NormalTable data={labourList} columns={labourColumns} />
              
               </Paper>
          </Grid2>
        </Grid2> */}
         <Grid2  size={5}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Staff List
              </Typography>
              </InputLabel>
              <Box sx={{display:"flex",gap:"1rem",alignItems:'center'}}>
              <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={labour}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={(e)=>{setLabour(e.target.value)}}
            />
              <IconButton sx={{border:`3px solid ${commonCss.red}`,p:0}} onClick={handleStaff} >
            <Add sx={{color:commonCss.red}}/>
         </IconButton>
              </Box>
          </Grid2>
        <Grid2 container size={12} alignItems={"center"} spacing={1}>
         
         
          <Grid2 size={7} sx={{display:labourList.length!==0?"default":"none"}}>
            <Paper elevation={4} >
               {/* <Collapse in={labour} > */}
                <NormalTable data={labourList} columns={labourColumns} />
               {/* </Collapse > */}
               </Paper>
          </Grid2>
        </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          CLASSIFIED AREA
          </Typography>
          </Grid2>
          
          <Grid2 container size={8}  >
          
           <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Where
              </Typography>
              </InputLabel>
           </Grid2>
          
           <Grid2 size={12} mt={values?.formDetail?.classifiedAreaWithWhat.includes(infoId.classifiedAreaWithWhatOtherId)?-11:-4}  >
            <FormGroup  >
            {classifiedWhere.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               value={typeLevel.id}
               checked={values?.formDetail?.classifiedAreaWhere.includes(typeLevel.id)}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                if(e.target.checked){
                  setFieldValue(`formDetail.classifiedAreaWhere`,[...values.formDetail.classifiedAreaWhere,typeLevel.id])
                }else{
                  const filterDataList=values.formDetail.classifiedAreaWhere.filter(fil=>fil!==typeLevel.id)
                  setFieldValue(`formDetail.classifiedAreaWhere`,filterDataList)
                }
                
                
               }}
             />
               
               } label={typeLevel.name} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          </Grid2>
          <Grid2 container size={4} >
          <Grid2 size={12}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              With What
              </Typography>
              </InputLabel>
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {classifiedWithWhat.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               checked={values?.formDetail?.classifiedAreaWithWhat.includes(typeLevel.id)}
               control={
               <Checkbox 
               value={typeLevel.id} 

               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                if(e.target.checked){
                  setFieldValue("formDetail.classifiedAreaWithWhat",[...values.formDetail.classifiedAreaWithWhat,typeLevel.id],'formDetail')
                }else{
                  const filterDataList=values.formDetail.classifiedAreaWithWhat.filter(fil=>fil!==typeLevel.id)
                  setFieldValue("formDetail.classifiedAreaWithWhat",filterDataList,'formDetail')
                }
               }}
             />
               
               } label={typeLevel.name} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={{xs:12}} sx={{display:values?.formDetail?.classifiedAreaWithWhat.includes(infoId.classifiedAreaWithWhatOtherId)?"default":"none"}}  >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={values.formDetail.classifiedAreaAnyOther}
              name="formDetail.classifiedAreaAnyOther"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={handleChange}
              
            />
          </Grid2>
          </Grid2>
          
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          OPERATIONAL INFORMATION
          </Typography>
          </Grid2>
          <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Where
              </Typography>
              </InputLabel>
           </Grid2>
           <Grid2 container size={12} spacing={0} gap={0}>
          {oiWhere.map((typeLevel,i)=>(
            <Grid2 size={{xs:6,md:3}} key={i}  >
               <FormControlLabel 
               
               control={
               <Checkbox 
               checked={values.formDetail.operationalInformationWhat.includes(typeLevel.id)}
               
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                 if(e.target.checked){
                  setFieldValue("formDetail.operationalInformationWhat",[...values.formDetail.operationalInformationWhat,typeLevel.id])
                }else{
                  const filterDataList=values.formDetail.operationalInformationWhat.filter(fil=>fil!==typeLevel.id)
                  setFieldValue("formDetail.operationalInformationWhat",filterDataList)
                }
               }}
             />
               
               } label={typeLevel.name} />
               </Grid2>
            ))}
            </Grid2>
            <Grid2 size={{xs:7}} sx={{display:values?.formDetail?.operationalInformationWhat.includes(infoId.operationalInformationOtherId)?"default":"none"}}  >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="operationalInformationWhat"
              // value={initialValue.formDetail.operationalInformationWhat}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              //onChange={(e)=>{handleInitialValue(e.target.name,e.target.value,"formDetail")}}
            />
          </Grid2>
            
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          ADDITIONAL PPE
          </Typography>
          </Grid2>
          <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Where
              </Typography>
              </InputLabel>
           </Grid2>
           <Grid2 container size={12} spacing={0} gap={0}>
          {apWhere.map((typeLevel,i)=>(
            <Grid2 size={{xs:6,md:3}} key={i}  >
               <FormControlLabel 
               
               control={
               <Checkbox  
               checked={values.formDetail.additionalPPPEWhere.includes(typeLevel.id)}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                
                if(e.target.checked){
                  setFieldValue("formDetail.additionalPPPEWhere",[...values.formDetail.additionalPPPEWhere,typeLevel.id])
                }else{
                  const filterDataList=values.formDetail.additionalPPPEWhere.filter(fil=>fil!==typeLevel.id)
                  setFieldValue("formDetail.additionalPPPEWhere",filterDataList)
                }
               }}
             />
               
               } label={typeLevel.name} />
               </Grid2>
            ))}
            </Grid2>
            <Grid2 size={{xs:12}}   >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Special clothing (specify)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="formDetail.specialclothingSpecified"
              value={values.formDetail.specialclothingSpecified}
              multiline
              rows={2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={handleChange}
            />
          </Grid2>
            
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 container size={5}>
          <Box sx={{width:"100%",display:"flex",flexDirection:"column",gap:"1rem"}}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          PRODUCT INVOLVED
          </Typography>
          </Grid2> 
          <Grid2 size={12}>
          <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Class
                  </Typography>
                </InputLabel>
                <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="formDetail.productInvolvedClassId"
                value={values.formDetail.productInvolvedClassId}
                onChange={handleChange}
                sx={{
                  borderRadius: commonCss.inputBorderRadius,
                  backgroundColor: commonCss.inputBackgroundColor,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: commonCss.inputBorderColor,
                  },
                }} 
                fullWidth
              >
                {productInvolved.map((inv,i)=>(
                    <MenuItem key={i} value={inv.id}>{inv.name}</MenuItem>
                ))}
              </Select>
          </Grid2>
          <Grid2 size={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="formDetail.productInvolvedOtherDesc"
            value={values.formDetail.productInvolvedOtherDesc}
            multiline
            rows={2}
            onChange={handleChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
       
        </Grid2>
        </Box>
        </Grid2>
        <Grid2 container size={5}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          WORKING AREA
          </Typography>
          </Grid2> 
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {workingArea.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               checked={values.workingArea[typeLevel.name]}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                setFieldValue(`workingArea.${typeLevel.name}`,e.target.checked)
               }}
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={12} sx={{display:values.workingArea.otherSpecified?"default":"none"}}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="workingArea.otherSpecifiedText"
            value={values.workingArea.otherSpecifiedText}
            onChange={handleChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
       
        </Grid2>
        </Grid2>

        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          OPERATIONAL MEASURES
          </Typography>
          </Grid2>
         
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          MECHANICAL
          </Typography>
              </Grid2> 
            <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Equipment
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="operationalMeasures.mechanical.equipment"
              value={values.operationalMeasures.mechanical.equipment}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              ID/Tag Number
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="operationalMeasures.mechanical.tagNO"
              value={values.operationalMeasures.mechanical.tagNO}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {mechnicalOM.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               checked={values.operationalMeasures.mechanical[typeLevel.name]}
               onChange={(e)=>{
                setFieldValue(`operationalMeasures.mechanical.${typeLevel.name}`,e.target.checked)
               }}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={{xs:12}}>
          <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Ventilation, specify
                  </Typography>
                </InputLabel>
                <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="operationalMeasures.mechanical.ventilationSpecified"
            value={values.operationalMeasures.mechanical.ventilationSpecified}
            onChange={handleChange}
            multiline
            rows={2}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
          </Grid2>
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                  See Isolation certificate
                </Typography>
              </InputLabel>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight} color={commonCss.green}
                 mt={1}
              >
                1. Isolation Certificate
              </Typography>
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          ELECTRICAL
          </Typography>
              </Grid2> 
            <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Instrument
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="operationalMeasures.electrical.equipment"
              value={values.operationalMeasures.electrical.equipment}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              ID/Tag Number
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="operationalMeasures.electrical.tagNO"
              value={values.operationalMeasures.electrical.tagNO}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {electricalOM.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               checked={values.operationalMeasures.electrical[typeLevel.name]}
               onChange={(e)=>{
                setFieldValue(`operationalMeasures.electrical.${typeLevel.name}`,e.target.checked)
               }}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
              
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                  See Isolation certificate
                </Typography>
              </InputLabel>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight} color={commonCss.green}
                 mt={1}
              >
                1. Isolation Certificate
              </Typography>
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          INSTRUMENTATION:{"<"}24
          </Typography>
              </Grid2> 
            <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Equipment/Electrical Motor (Specify):
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name='operationalMeasures.instrumentation.equipment'
              value={values.operationalMeasures.instrumentation.equipment}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              ID/Tag Number
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name='operationalMeasures.instrumentation.tagNO'
              value={values.operationalMeasures.instrumentation.tagNO}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {instrumentationOM.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               checked={values.operationalMeasures.instrumentation[typeLevel.name]}
               onChange={(e)=>{
                setFieldValue(`operationalMeasures.instrumentation.${typeLevel.name}`,e.target.checked)
               }}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
              
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                  See Isolation certificate
                </Typography>
              </InputLabel>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight} color={commonCss.green}
                 mt={1}
              >
                1. Isolation Certificate
              </Typography>
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={"0.5rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          WORKING AREA
          </Typography>
          </Grid2 >
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor}>
            Permit Supervisor is aware of the safety instructions as in the:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor}>
                • Terminal safety manual
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor}>
                • Permit to Work and associated certificates
              </Typography>
            </ListItem>
          </List>
        </Grid2>
        <Grid2 container size={12} mb={2} mt={-1}>
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Name
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.workingAreaTechnicianName}
              slotProps={{
                input: {
                  readOnly: true, // Set readOnly to true
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
            {/* <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="technicianId"
                value={values.workingArea.pspSupervisorId}
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                fullWidth
                onChange={handleChange}
              >
                {technician.map((userTech,i)=>(
                  <MenuItem key={i} value={userTech.id} onClick={()=>{setHolderName({...holderName,supEmail:userTech.emailId})}}>{userTech.firstName+" "+userTech.lastName}</MenuItem>
                ))}
              </Select> */}
          </Grid2>
          {/* <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Email
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.supEmail}
              slotProps={{
                input: {
                  readOnly: true, // Set readOnly to true
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> */}
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"

                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
          <Grid2 container size={4}  alignItems={"flex-end"}>
            <Button
            variant="contained"
            disabled={values.technicianId===null}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw",
              height:"6vh"
            }}
            onClick={()=>{
              // setFieldValue("isEngineerApproved",true)
              setFieldValue("workingArea.technicianId",values.technicianId)
              setHolderName({...holderName,workingAreaTechnicianName:holderName.technicianName})
              // setFieldValue("engineerApprovedDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
              
            }}
          >
            Approve
          </Button>
            </Grid2>
        </Grid2>
          </Grid2>
          <Grid2 container size={12} spacing={"0.5rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          Operational Measures: To be signed by Operation Executive (for MPP)
          or Controller (for KVDT)
          </Typography>
          </Grid2 >
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {measureList.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               checked={values.operationalMeasures[typeLevel.name]}
               onChange={(e)=>{setFieldValue(`operationalMeasures.${typeLevel.name}`,e.target.checked)}}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
              //  onChange={(e)=>{
                
              //   if(typeLevel.label==="Others"&&e.target.checked){
              //     setWorkAreaOthers(true)
              //   }else{
              //     setWorkAreaOthers(false)
              //   }
              //  }}
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                 Any Other Measure
                </Typography>
              </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={2}
            name="operationalMeasures.otherMeasures"
            value={values.operationalMeasures.otherMeasures}
            onChange={handleChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
       
        </Grid2>
        <Grid2 container size={12}>
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Name
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
         
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2>
          <Grid2 container size={4}  alignItems={"flex-end"}>
            <Button
            variant="contained"
            
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw",
              height:"6vh"
            }}
            onClick={()=>{
              // setFieldValue("isEngineerApproved",true)
              // setFieldValue("workingArea.technicianId",values.technicianId)
              // setFieldValue("engineerApprovedDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
              
            }}
          >
            Approve
          </Button>
            </Grid2>
        </Grid2>
          </Grid2>
         
          <Grid2 container size={12} justifyContent={'flex-end'}>
           
            <Button
            size="small" 
            variant='contained'
            type="submit"
            sx={{
                backgroundColor:commonCss.green,
                borderRadius: commonCss.buttonBorderRadius ,
                textTransform: "none", 
                padding: "0.6rem 1rem", 
                color:commonCss.buttonTextColor,
                width:"10vw"
            }}
          >
            Next
            
          </Button>
          
          </Grid2>
          </Paper>
          
   <Dialog
        open={certificateOpen}
        onClose={()=>{setCertificateOpen(false)}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"50vw"},
            width:{xs:"100vw",md:"55vw"}
          },
        }}
        children={
         <CertificateSelect handleCloseClick={()=>{
          setCertificateOpen(false)
          getData()
         
          // updateCertificate()
        }} data={values}  />
        }
        />
         {/* <Dialog
        open={labour}
        onClose={()=>{setLabour(false)}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"50vw"},
            width:{xs:"100vw",md:"55vw"}
          },
        }}
        children={
         <LabourSelect handleCloseClick={()=>{setLabour(false)}}/>
        }
        /> */}
        
        <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.radiation}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,radiation:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <RadiationCertificate certificateDetail={certificateInfo} handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,radiation:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.isolation}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,isolation:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <IsolationCertificate certificateDetail={certificateInfo} handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,isolation:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.lifting}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,lifting:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <LiftingAndHoisting certificateDetail={certificateInfo}  handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,lifting:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.excavation}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,excavation:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Excavation location={values.formDetail.locationText} certificateDetail={certificateInfo} handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,excavation:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.confinedSpace}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,confinedSpace:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <ConfinedSpaceCertificate certificateDetail={certificateInfo} handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,confinedSpace:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.hotWork}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,hotWork:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <HotWork certificateDetail={certificateInfo} handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,hotWork:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.safe}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,safe:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <SafeOverride certificateDetail={certificateInfo} handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,safe:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={riskLevelBol.isMethodStatementRequired}
  onClose={()=>{setRiskLevelBol({...riskLevelBol,isMethodStatementRequired:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <MethodStatement dataInfo={dataInfo} handleCloseClick={()=>{setRiskLevelBol({...riskLevelBol,isMethodStatementRequired:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={riskLevelBol.isRiskAssessmentRequired}
  onClose={()=>{setRiskLevelBol({...riskLevelBol,isRiskAssessmentRequired:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <RiskAssessment handleCloseClick={()=>{setRiskLevelBol({...riskLevelBol,isRiskAssessmentRequired:false})}}/>
  </DialogContent>
       </Dialog>
      </Form>
        )}
        
          
         
       </Formik>
  } 
   </>
  );
};

export default PTWForm1;
